<template>
  <transition
    enter-active-class="animated fadeInLeft"
    leave-active-class="animated fadeOutLeft"
  >
    <div v-if="flag">
      <div class="changeRole">
        <i class="el-icon-close" @click="$emit('close', false)"></i>
        <img
          class="avatar"
          :src="roleList[role.avatar]"
          @click="choseFlag = !choseFlag"
        />
        <h1>年龄</h1>
        <h3>
          <input type="text" v-model="info.nianling" placeholder="请输入您的年龄" />
        </h3>
        <h1>职业</h1>
        <h3><input type="text" v-model="info.zhiye" placeholder="请输入您的职业" /></h3>
        <h1>公司</h1>
        <h3><input type="text" v-model="info.gongsi" placeholder="请输入您的公司" /></h3>
        <h1>行业</h1>
        <h3><input type="text" v-model="info.hangye" placeholder="请输入您的行业" /></h3>
        <div class="name">{{ role.name }}</div>

        <img src="../assets/newImg/function/queding.png" @click="confirm" class="qd" />
      </div>
      <transition
        enter-active-class="animated fadeInLeft"
        leave-active-class="animated fadeOutLeft"
      >
        <div class="changeAvatar" v-if="choseFlag">
          <img
            v-for="(item, index) in roleList"
            :key="index"
            :src="item"
            :class="index == role.avatar ? 'active' : ''"
            @click="change(index)"
          />
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { save } from "../assets/api/index";
export default {
  props: ["flag", "roleList", "role"],
  data() {
    return {
      choseFlag: false,

      info: {},
    };
  },
  methods: {
    change(e) {
      this.$emit("changeAvatar", e);
      this.choseFlag = false;
    },

    confirm() {
      var role = this.$chat.role;

      if (
        !this.info.nianling ||
        !this.info.zhiye ||
        !this.info.gongsi ||
        !this.info.hangye
      ) {
        this.$message.error("还有未填项");
        return;
      }
      role.info = this.info;

      if (this.$chat.role.auth < 2) {
        this.$chat.role.auth = 2;
      }

      console.log(JSON.stringify(role), role.info);
      save({
        name: role.name,
        info: JSON.stringify(role),
        auth: this.$chat.role.auth,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "认证成功",
          });
          this.$emit("close", false);
        }
      });
    },
  },

  created() {},

  watch: {
    flag(val) {
      console.log(val);
      if (val) {
        if (this.$chat.role.info) {
          this.info = this.$chat.role.info;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.changeRole {
  width: 340px;
  background: rgb(38, 38, 38);
  position: fixed;
  padding: 12px 12px 0 12px;
  left: 10px;
  top: 70px;

  .el-icon-close {
    position: absolute;
    color: white;
    right: 10px;
    cursor: pointer;
  }
  .avatar {
    width: 100px;
    height: 100px;
    background: #474747;
    border-radius: 2px;
    position: absolute;
    top: 12px;
    right: 36px;
    border-radius: 50%;
    cursor: pointer;
  }
  h1 {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #bebebe;
  }
  h3 {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    margin: 8px 0 16px 0;
  }
  .name {
    position: absolute;
    color: white;
    right: 36px;
    top: 120px;
    width: 100px;
    text-align: center;
  }
  .qd {
    position: absolute;
    bottom: -20px;
  }
}
.changeAvatar {
  width: 140px;
  height: 248px;
  background: #34383d;
  border-radius: 2px;
  position: absolute;
  top: 70px;
  left: 385px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  img {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    cursor: pointer;
  }
  .active {
    outline: 2px solid #1dd3b0;
  }
}

input {
  color: #ffffff;
  background: transparent;
  border: 0;
}
</style>
