<template>
  <div>
    <!-- 人物信息 -->
    <img
      class="avatar"
      :src="url ? url : require('../assets/img/role1.jpg')"
      @click="msgShow = !msgShow"
    />
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div class="showBox" v-if="msgShow">
        <h3>设置</h3>
        <div class="input">
          <img class="avatar1" :src="url ? url : require('../assets/img/role1.jpg')" />

          <el-input v-model="nickname" placeholder="请输入内容"></el-input>
        </div>
        <div class="button">
          <div class="bn1" @click="msgShow = false">取消修改</div>
          <div class="bn2" @click="confirm">确认修改</div>
        </div>
      </div>
    </transition>
    <transition
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <Roam v-if="roamFlag" @close="roamFlag = false" />
    </transition>
    <!-- 右侧菜单 -->
    <div class="rightFuntion">
      <div
        v-for="(item, index) in rightFunction"
        :key="index"
        @click="fun(index)"
        style="margin-right: 20px"
      >
        <img :src="item.i" />
        <p>{{ item.f }}</p>
      </div>
    </div>

    <!-- <img src="../assets/mobile/map.png" class="map" @click="back" /> -->
    <div class="more" @click="more">
      <!-- <img src="../assets/mobile/more.png" /> -->

      <!-- <p @click="moreFlag = !moreFlag">更多</p> -->
      <transition
        enter-active-class="animated fadeInLeft"
        leave-active-class="animated fadeOutLeft"
      >
        <div class="moreFunction" v-if="moreFlag">
          <div class="moreList" @click="zipai">屏幕快照</div>

          <div class="moreList" @click="loginOut">退出登录</div>
        </div>
      </transition>
    </div>

    <img src="../assets/mobile/dianzan.png" class="dianzan" @click="guzhang" />

    <img src="../assets/mobile/guzhang.png" class="guzhang" @click="dianzan" />

    <img src="../assets/mobile/qili.png" class="qili" @click="qili" v-if="sitFlag" />
    <!-- <div class="leftFuntion">
      <div style="margin-right: 20px" @click="csflag = !csflag">
        <img src="../assets/newImg/舞台.png" style="width: 44px" />
        <p>传送门</p>
      </div>
    </div> -->

    <!-- 传送 -->
    <div class="action1" v-if="csflag">
      <div class="box" @click="cs('展区')">展区</div>
      <div class="box" @click="cs('vip')">vip席</div>
      <div class="box" @click="cs('观众席')">观众席</div>
    </div>
    <!-- 换装 -->
    <transition
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <Change v-if="changeFlag" @close="changeFlag = false" />
    </transition>

    <!-- 动作 -->

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <Action v-if="actionFlag && sitFlag" />
    </transition>
    <!-- 
    <Tishi1 /> -->
  </div>
</template>

<script>
import { rename } from "../assets/api/index.js";
import Tishi1 from "../components/tishi1.vue";
import Role from "./role.vue";
import Change from "./change.vue";
import Action from "./action.vue";
import Roam from "./roam.vue";
export default {
  data() {
    return {
      roleFlag: false,
      roamFlag: false,
      changeFlag: false,
      actionFlag: false,
      sitFlag: false,
      csflag: false,
      url: "",
      nickname: "",
      msgShow: false,
      moreFlag: false,
      //右侧菜单
      rightFunction: [
        {
          i: require("../assets/mobile/yuyin.png"),
          f: "语音",
        },
        {
          i: require("../assets/mobile/liaotian.png"),
          f: "聊天",
        },
        {
          i: require("../assets/mobile/huanzhuang.png"),
          f: "换装",
        },

        {
          i: require("../assets/mobile/logout.png"),
          f: "退出",
        },
      ],
    };
  },
  components: {
    Role,
    Change,
    Action,
    Roam,
    Tishi1,
  },
  methods: {
    confirm() {
      rename({
        id: this.$chat.role.id,
        name: this.nickname,
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success("修改昵称成功");
          let msg = 1056 + ",";
          let info = { msg: "setName", data: this.nickname };
          msg += JSON.stringify(info);
          this.$chat.ws.send(msg);
          this.Bus.$emit("changeName", this.nickname);
        }
        this.msgShow = false;
      });
    },
    qili() {
      this.Bus.$emit("stand", "");
    },
    fun(e) {
      console.log(e);
      if (e == 2) {
        if (this.sitFlag == true) {
          this.$message.error("坐下无法换装");
          return;
        }
        this.changeFlag = !this.changeFlag;
      }

      if (e == 3) {
        this.$confirm(this.$t("info.lang.logOutInfo"))
          .then((_) => {
            localStorage.removeItem("info");
            location.reload();
            done();
          })
          .catch((_) => {});
      }
      if (e == 1) {
        this.Bus.$emit("chatFlag", true);
      }

      if (e == 0) {
        this.Bus.$emit("clientFlag", true);
      }
    },
    loginOut() {
      this.$confirm(this.$t("info.lang.logOutInfo"))
        .then((_) => {
          localStorage.removeItem("info");
          location.reload();
          done();
        })
        .catch((_) => {});
    },

    zipai() {
      this.moreFlag = false;
      var date = new Date().getTime();
      // var name = `自拍${date}`;
      this.compress(document.o2.shot(), 1920, 1080).then((res) => {
        this.$notify({
          title: "成功",
          message: "自拍成功",
          type: "success",
        });
      });
    },

    compress(base64String, w, quality) {
      let a = document.createElement("a");

      a.style.display = "none";
      var that = this;
      var getMimeType = function (urlData) {
        var arr = urlData.split(",");
        var mime = arr[0].match(/:(.*?);/)[1];
        // return mime.replace("image/", "");
        return mime;
      };
      var newImage = new Image();
      var imgWidth, imgHeight;

      var promise = new Promise((resolve) => (newImage.onload = resolve));
      newImage.src = base64String;

      return promise.then(() => {
        imgWidth = newImage.width;
        imgHeight = newImage.height;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        if (Math.max(imgWidth, imgHeight) > w) {
          if (imgWidth > imgHeight) {
            canvas.width = w;
            canvas.height = (w * imgHeight) / imgWidth;
          } else {
            canvas.height = w;
            canvas.width = (w * imgWidth) / imgHeight;
          }
        } else {
          canvas.width = imgWidth;
          canvas.height = imgHeight;
        }
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(newImage, 0, 0, canvas.width, canvas.height);

        let imgobj1 = new Image();
        imgobj1.src = require("../assets/newImg/banner2.png");

        var base64 = canvas.toDataURL(getMimeType(base64String), quality);
        var blob = that.dataURLToBlob(base64);
        a.setAttribute("href", URL.createObjectURL(blob));
        a.setAttribute("download", "自拍.png");
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(blob);
        document.body.removeChild(a);

        // return base64;
      });
    },

    dataURLToBlob(dataurl) {
      //ie 图片转格式
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },

    more() {},

    cs(e) {
      this.csflag = false;
      if (e == "展区") {
        document.o2.setRolePos(4122, 2521, -26166, 2476, 4595, -28797);
      } else if (e == "观众席") {
        document.o2.setRolePos(19418, 1622, 797, 22237, 3795, 1894);
      } else if (e == "vip") {
        document.o2.setRolePos(9780, 124, 13205, 10624, 2456, 16096);
      }
    },

    guzhang() {
      let str = "1056,gif,1";
      this.$chat.ws.send(str);
    },

    dianzan() {
      let str = "1056,gif,0";
      this.$chat.ws.send(str);
    },

    back() {
      this.$confirm("是否返回梦界空间大地图")
        .then((_) => {
          localStorage.setItem("dreamType", 0);
          location.reload();
          done();
        })
        .catch((_) => {});
    },
  },

  created() {
    this.Bus.$on("onRoleLoaded", (role) => {
      role.onStateChg.push((state) => {
        if (state == "sit") {
          this.sitFlag = true;
        } else {
          this.sitFlag = false;
        }
      });
    });

    this.Bus.$on("loginS", (e) => {
      this.role = this.$chat.role;
      if (!this.role.avatar) {
        this.role.avatar = 0;
      }
      this.auth = this.$chat.role.auth;
      this.type = this.$chat.role.type;
      this.url = this.$chat.role.url;

      this.nickname = this.$chat.role.name;
    });

    this.Bus.$on("actionClose", () => {
      this.actionFlag = false;
    });

    this.Bus.$on("roamClose", () => {
      this.roamFlag = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.moreFunction {
  position: absolute;
  left: 40px;
  .moreList {
    width: 142.86px;
    height: 34.81px;
    background: url("../assets/mobile/moreList.png");
    background-size: 100% 100%;
    margin-bottom: 10px;
    color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.map {
  position: fixed;
  width: 70px;
  height: 70px;
  top: 14px;
  left: 20px;
}

.dianzan {
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.guzhang {
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 80px;
  right: 60px;
}

.qili {
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 20px;
  right: 100px;
}

.more {
  width: 30px;

  position: fixed;
  left: 125px;
  top: 30px;
  img {
    width: 100%;
  }

  p {
    font-size: 12px;
    color: white;
  }
}

.function1 {
  position: absolute;
  top: 14px;
  left: 11px;
}

.rightFuntion,
.leftFuntion {
  top: 14px;
  right: 11px;
  display: flex;
  position: absolute;
  z-index: 10000;

  img {
  }

  p {
    width: 60px;
    font-size: 12px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    text-shadow: 0px 3px 4px 0px rgba(33, 58, 139, 0.92);
    text-align: center;
    margin-top: 5px;
  }
}
.leftFuntion {
  left: 120px;
  z-index: 1;
  width: 100px;
  top: 30px;
}
.rightFuntion {
  right: 0px;

  img {
    width: 40px;
  }
}

.action1 {
  width: 66px;
  position: absolute;
  left: 118px;
  top: 108px;
  z-index: 10000;

  .box {
    width: 66px;
    height: 34px;
    background: #ffffff;
    border-radius: 17px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: center;
    color: #333333;
  }
}

.avatar {
  width: 60px;
  height: 60px;
  position: fixed;
  left: 25px;
  top: 25px;
  background: #fff;
  z-index: 50;
  border-radius: 50%;
  cursor: pointer;
}

.showBox {
  background-color: rgb(38, 38, 38);
  width: 258px;
  height: 240px;
  padding: 0 15px;
  background: #262626;
  border-radius: 6px;
  position: fixed;
  left: 70px;
  top: -16px;
  border-radius: 5px;
  transform: scale(0.7);
  h3 {
    width: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    border-bottom: 1px solid #191919;
    color: white;
    padding: 10px 0;
  }
  .avatar1 {
    width: 80px;
    height: 80px;

    background: #fff;

    border-radius: 50%;
    cursor: pointer;
    margin-right: 15px;
  }
  p {
    width: 70px;
    color: white;
    margin-left: 5px;
    font-size: 14px;
  }
}

.input {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 17px;
}

.button {
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.bn1 {
  width: 80px;
  height: 27px;
  background: #262626;
  border: 1px solid #ffffff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-right: 25px;
  font-size: 12px;
}

.bn2 {
  width: 80px;
  height: 27px;
  background: #ff0000;
  border-radius: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
</style>
