<template>
  <div>
    <div class="userList">
      <img src="../assets/newImg/function/close.png" class="close" @click="close" />
      <div
        v-for="(item, index) in list"
        :key="index"
        :class="index == value ? 'name active' : 'name'"
      >
        <!-- <p v-if="item.name == $chat.role.name" style="color: orange">{{ item.name }}</p> -->
        <p>{{ item.name }}</p>
        <p v-if="item.vip == 5" style="color: orange">主持人</p>

        <p v-else @click="check(item.id, index)">操作</p>
      </div>
    </div>
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div class="check" v-if="flag">
        <el-button type="primary" @click="save">禁言</el-button>
        <el-button type="primary" @click="out">踢出房间</el-button>
        <el-button
          type="error"
          @click="
            flag = false;
            value = -1;
          "
          >取消</el-button
        >
      </div>
    </transition>
  </div>
</template>

<script>
import { check_status, change_status } from "../assets/api/index";
export default {
  data() {
    return {
      list: [],
      value: -1,
      type: [],
      flag: false,
      name: "",
      id: 0,
    };
  },
  methods: {
    check(e, index) {
      this.id = e;
      this.value = index;
      this.flag = true;
    },
    save() {
      let data = { msg: "allmute", data: this.id };
      this.$chat.ws.send("1056," + JSON.stringify(data));
    },

    out() {
      let data = { msg: "roomOut", data: this.id };
      this.$chat.ws.send("1056," + JSON.stringify(data));
      setTimeout(() => {
        this.$message.success("您已经将其踢出房间");
      }, 500);

      this.flag = false;
    },

    close() {
      this.$emit("close", false);
    },
  },
  created() {
    this.list = this.$chat.playerList;

    this.Bus.$on("playerList", (e) => {
      console.log(e);
      this.list = e;

      // e.forEach((element) => {
      //   console.log(element.vip);

      //   this.list.push(element);
      // });
    });
  },
};
</script>

<style lang="scss" scoped>
.userList {
  position: fixed;
  left: 0;
  width: 250px;
  height: 400px;
  background: rgba($color: #262626, $alpha: 0.8);

  top: 65px;
  padding-top: 40px;
  overflow-y: scroll;
  z-index: 9999;

  .name {
    height: 30px;
    justify-content: space-between;
    color: #dfdfdf;
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-size: 14px;
    p {
      cursor: pointer;
    }
  }
  .active {
    color: #1dd3b0;
  }
}

.check {
  position: fixed;
  left: 251px;
  width: 273px;
  height: 100px;
  top: 65px;
  background: rgba($color: #262626, $alpha: 0.8);
  padding-top: 20px;

  .el-checkbox {
    margin-left: 20px;
    width: 100%;
    text-align: left;
    color: #dfdfdf;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .el-button {
    font-size: 12px;
    margin-top: 20px;
  }
}
.userList::-webkit-scrollbar {
  width: 4px;
  opacity: 0.6;
}

.userList::-webkit-scrollbar-track {
  background: #ffffff;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

.userList::-webkit-scrollbar-thumb {
  background-color: #1dd3b0;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
.close {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
</style>
