<template>
  <div class="all">
    <div class="role">
      <img class="avatar" :src="roleList[$chat.role.avatar]" />
      <div class="name">{{ $chat.role.name }}</div>

      <div class="function">
        <div class="box" @click="onlineFlag = !onlineFlag">
          <img src="../assets/newImg/mobile/role1.png" />
          <p>{{ $t("info.lang.online") }}</p>
        </div>

        <div class="box" @click="loginOut">
          <img src="../assets/newImg/mobile/role2.png" />
          <p>{{ $t("info.lang.logOut") }}</p>
        </div>

        <div class="box">
          <img
            src="../assets/newImg/mobile/role3.png"
            @click="changeL('en')"
            v-if="lang == 'zh'"
          />

          <img
            src="../assets/newImg/mobile/role33.png"
            @click="changeL('zh')"
            v-if="lang == 'en'"
          />
          <p>{{ $t("info.lang.language") }}</p>
        </div>

        <div class="box" @click="close">
          <img src="../assets/newImg/mobile/role4.png" />
          <p>{{ $t("info.lang.close") }}</p>
        </div>
      </div>
    </div>

    <transition
      enter-active-class="animated fadeInLeft"
      leave-active-class="animated fadeOutLeft"
    >
      <div class="online" v-if="onlineFlag">
        <h3>{{ list.length }} {{ $t("info.lang.peopleOnline") }}</h3>
        <div class="nameBox" v-for="(item, index) in list" :key="index">
          {{ item.name }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      roleList: [
        require("../assets/img/role1.jpg"),
        require("../assets/img/role2.jpg"),
        require("../assets/img/role3.png"),
        require("../assets/img/role4.png"),
        require("../assets/img/role5.jpg"),
        require("../assets/img/role6.jpg"),
      ],
      lang: "",

      list: [],

      onlineFlag: false,
    };
  },
  methods: {
    //改变语言
    changeL(val) {
      localStorage.setItem("locale", val);
      this.lang = val;
      this.$i18n.locale = val;
    },
    //退出登录
    loginOut() {
      this.$confirm(this.$t("info.lang.logOutInfo"))
        .then((_) => {
          localStorage.removeItem("info");
          location.reload();
          done();
        })
        .catch((_) => {});
    },
    close() {
      console.log("close");
      this.$emit("close", false);
    },
  },

  created() {
    var lang = localStorage.getItem("locale");
    this.lang = lang ? lang : "zh";

    this.list = this.$chat.playerList;
  },
};
</script>

<style lang="scss" scoped>
.all {
  position: absolute;
  top: 0;
  width: 152px;
  height: 100%;
}
.role {
  width: 152px;
  height: 100%;
  background: #ffffff;
  box-shadow: 5px 0px 25px 0px rgba(0, 0, 0, 0.06);
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  .avatar {
    width: 68px;
    height: 68px;
    margin-top: 20px;
    border-radius: 50%;
  }
  .name {
    font-size: 16px;
    font-family: PingFangSC-Medium;
    text-align: center;
    color: #24272b;
    margin-bottom: 40px;
  }

  .function {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .box {
    width: 60px;
    height: 60px;
    position: relative;
    margin-bottom: 16px;
    img {
      width: 100%;
      height: 100%;
    }
    p {
      position: absolute;
      bottom: 0;
      font-size: 11px;
      font-family: PingFangSC-Medium;
      text-align: center;
      color: #3e4a59;
      width: 100%;
      bottom: 2px;
    }
  }
}

.online {
  width: 100px;
  padding: 0 10px;
  height: 100%;
  background: #f2f8ff;
  position: absolute;
  left: 152px;
  top: 0;
  overflow-y: scroll;
  h3 {
    font-size: 12px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    text-align: left;
    color: #2173ed;
    margin-top: 20px;
    margin-bottom: 14px;
  }
  .nameBox {
    width: 80px;
    padding: 0 10px;
    height: 26px;
    background: #ffffff;
    box-shadow: 0px 3px 8px 0px rgba(9, 95, 221, 0.16);
    font-size: 12px;
    font-family: PingFangSC-Medium;
    text-align: center;
    color: #3e4a59;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 26px;
    margin-bottom: 10px;
  }
}

.online::-webkit-scrollbar {
  width: 5px;
  opacity: 0.6;
}

.online::-webkit-scrollbar-track {
  background: #ffffff;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

.online::-webkit-scrollbar-thumb {
  background-color: #1dd3b0;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
</style>
