<template>
  <div class="circle" id="circle" @touchstart="start">
    <img
      src="../assets/mobile/btn.png"
      :style="'left:' + left + 'px;top:' + top + 'px'"
      class="hp"
    />
  </div>
</template>

<script>
import Bus from "../../common/js/bus";
export default {
  data() {
    return {
      left: 0,
      top: 0,
      position: {
        x: 0,
        y: 0,
      },
    };
  },
  methods: {
    start(e) {
      this.position.x = e.touches[0].clientX;
      this.position.y = e.touches[0].clientY;
      document.getElementById("circle").addEventListener("touchmove", this.move);
      document.getElementById("circle").addEventListener("touchend", this.end);
    },
    move(e) {
      e.preventDefault();
      this.position.x = e.touches[0].clientX;
      this.position.y = e.touches[0].clientY;
    },
    end() {
      console.log("end");

      document.getElementById("circle").removeEventListener("touchmove", this.move);

      this.left = 0;
      this.top = 0;
      this.position = {
        x: 0,
        y: 0,
      };

      Bus.$emit("key", "all");
    },
  },
  created() {},
  watch: {
    "position.x": {
      deep: true,
      handler: function (newV, oldV) {
        if (newV != 0 && oldV) {
          if (document.documentElement.clientWidth > 500) {
            var x = newV - oldV;
            if (this.left > 65) this.left = 65;
            else if (this.left < -75) this.left = -75;
            else if (x) this.left += x;
          } else {
            var x = newV - oldV;
            if (this.top > 65) this.top = 65;
            else if (this.top < -75) this.top = -75;
            else if (x) this.top -= x;
          }
        }

        console.log(this.left, "当前到中心点的x轴距离",document.o2.orientation);
        console.log(x);
        Bus.$emit("yaogan", this.left, this.top);
      },
    },

    "position.y": {
      deep: true,
      handler: function (newV, oldV) {
        if (newV != 0 && oldV) {
          if (document.documentElement.clientWidth > 500) {
            var y = newV - oldV;
            if (this.top > 75) this.top = 75;
            else if (this.top < -75) this.top = -75;
            else if (y) this.top += y;
          } else {
            var x = newV - oldV;
            if (this.left > 65) this.left = 65;
            else if (this.left < -75) this.left = -75;
            else if (x) this.left += x;
          }
        }
        console.log(this.top, "当前到中心点的y轴距离");
        Bus.$emit("yaogan", this.left, this.top);
      },
    },
  },
  mounted() {
    // document.getElementById("circle").addEventListener(
    //   "touchmove",
    //   function (e) {
    //     e.preventDefault();
    //   },
    //   { passive: false }
    // );
  },
};
</script>

<style scoped lang="scss">
.circle {
  width: 150px;
  height: 150px;

  border-radius: 50%;
  background: url("../assets/mobile/pic.png");
  background-size: 100% 100%;
  position: absolute;
  left: 30px;
  bottom: 20px;

  img {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 0;
    bottom: 0;
    margin: auto;
    transform: 0.1s;
  }
}
</style>
