<template>
  <div class="all">
    <div class="itemList">
      <h3></h3>
      <div
        :class="value1 == index ? 'item active' : 'item'"
        v-for="(item, index) in npcList"
        :key="index"
        @click="change1(index)"
      >
        <img :src="item" style="width: 100%; height: 100%; border-radius: 50%" />
      </div>

      <div class="functionBtn" style="bottom: -25px">
        <img
          src="../assets/role/qr.png"
          @click="confirm"
          style="width: 40px; height: 40px"
        />
        <!-- <img src="../assets/newImg/function/quxiao.png" /> -->
      </div>
    </div>
    <!-- <div style="display: flex">
      <div class="chooseList">
        <div
          v-for="(item, index) in $t('info.lang.AccName')"
          :key="index"
          :class="value == index ? 'active choose' : 'choose'"
          @click="change(index)"
        >
          <p>{{ item }}</p>
          <i class="el-icon-arrow-right"></i>
        </div>

        <div class="functionBtn">
          <img src="../assets/newImg/function/queding.png" @click="confirm" />
        
        </div>
      </div>

      <div class="itemList">
        <h3>
          <p>请选择</p>
        </h3>
        <div
          :class="value1 == index + 1 ? 'item active' : 'item'"
          v-for="(item, index) in list1[value][accinfo[0] - 1]"
          :key="index"
          @click="change1(index, item)"
        >
          <div
            v-if="value == 5 || value == 6"
            :style="'width:100%;height:100%;background:' + item"
          ></div>
          <img :src="item" v-else style="width: 100%; height: 100%" />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: ["flag"],
  data() {
    return {
      list: [],
      value: 0,
      value1: 99,
      npcList: [
        require("../assets/role/role1.png"),
        require("../assets/role/role2.png"),
        require("../assets/role/role3.png"),
        require("../assets/role/role6.png"),
        require("../assets/role/role5.png"),
        require("../assets/role/role4.png"),
      ],
    };
  },
  methods: {
    change(e) {
      this.value = e;
      this.value1 = 99;
    },
    change1(b) {
      console.log(b);
      this.value1 = b;
      if (document.o2.role) {
        document.o2.role.setPlayer({ modelIdx: parseInt(b) });
      }
    },

    confirm() {
      this.$emit("close", false);
      if (document.o2.role) {
        document.o2.role.save();
        let msg = 1056 + ",";
        let info = { msg: "setPlayer", data: document.o2.role.info };
        msg += JSON.stringify(info);
        this.$chat.ws.send(msg);
      }
    },
  },

  created() {},

  watch: {},
};
</script>

<style lang="scss" scoped>
.all {
  position: absolute;
  right: 0;
  top: 90px;
  display: flex;
  .chooseList {
    padding: 10px 0 25px 0;
    background: rgba($color: #fff, $alpha: 0.8);
    width: 145px;
    position: relative;
    .choose {
      display: flex;
      cursor: pointer;
      color: #fff;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      p {
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        text-align: left;

        width: 80px;
        display: flex;
        align-items: center;
        margin-left: 10px;
        height: 36px;
      }
      .el-icon-arrow-right {
        color: #fff;
        margin-right: 10px;
      }
    }
    .active {
      background: #1c1c1c;
      color: #1dd3b0;
      transition: 0.5s;
    }
  }
  .itemList {
    width: 250px;
    height: 150px;
    background: rgba($color: #fff, $alpha: 0.8);
    padding: 8px;
    justify-content: center;
    display: flex;

    flex-wrap: wrap;
    h3 {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      color: white;
      height: 5px;
    }
    .item {
      width: 40px;
      height: 40px;
      // background: #414141;

      border-radius: 4px;
      margin: 0 20px;
      cursor: pointer;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .active {
      border-radius: 4px;
      border-radius: 50%;
      outline: 2px solid #4770ff;
    }
  }

  .functionBtn {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    img {
      cursor: pointer;
    }
  }
}
</style>
