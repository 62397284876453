<template>
  <div
    class="shadow"
    v-loading="flag"
    :element-loading-text="'正在上传资源中' + progress + '%'"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.5)"
  ></div>
</template>
<script>
import { playerType, player } from "../../common/js/player";
import * as THREE from "three/build/three.module";
import Bus from "../../common/js/bus";
import axios from "axios";

export default {
  props: ["viewHeight", "itemName", "floor", "speed", "ios"],
  data() {
    return { flag: false, bAdminView: false, keyFlag: false, url: "", progress: 0 };
  },
  mounted() {
    //创建oss client
    this.$o2Function.createClient();
    this.url = document.o2.url;

    console.log(this.url);
    var that = this;

    Bus.$on('tdaudio',flag=>{
      _o2.screen_video.volume = flag?1:0;
    })

    Bus.$on("yaogan", (x, y) => {
      bAutoMove = false;
      moveDir.set(x, 0, y);
    });
    Bus.$on("stand", () => {
      // if (document.o2.role.state != "sit") {
      //   this.$message.error("该动作只有坐下才能使用");
      //   return;
      // } else
      {
        _o2.fixed_view = false;
        _o2.cameraControls.maxDistance = 5000;
        bAutoMove = false;
        console.log("站起来");
        if (name_sit) {
          //let msg = "up," + name_sit;
          if (_o2.role) _o2.role.bHand = false;
          let role = that.$chat.role;
          let str = netCmd.CMD_USER_STAND_UP + "," + name_sit + "," + role.id;
          that.$chat.ws.send(str);
          // let requestSitUpUrl =
          //   "https://api.wh12345.net:9300/up/?pid=" +
          //   role.id +
          //   "&pname=" +
          //   role.name +
          //   "&sitname=" +
          //   name_sit;
          // axios.get(requestSitUpUrl);
          //that.$chat.ws.send(msg);
          name_sit = null;
        }

        if (current_rtc && !current_region) {
          current_rtc = 0;
          Bus.$emit("clientOut", true);
        }
        bTempSit = false;
        old_angle = angle_sit;
      }
    });
    Bus.$on("flag", (target) => {
      that.flag = target;
    });
    Bus.$on("stream", (target) => {
      console.log(target);
      if (target == "local_stream") {
        document.o2.onLocalStreamAdd();
      } else if (target == "shareScreen") {
        let div = document.getElementById(target);
        //console.log(div);
        if (div) {
          let interval = setInterval(() => {
            let arr = div.getElementsByTagName("video");
            console.log(arr.length);
            if (arr && arr.length > 0) {
              let video = arr[0];
              scene_state.screen_url = video.id;
              _o2.share_video = video.id;
              check_scene_state();
              clearInterval(interval);
            }
          }, 500);
        }
      } else if (target == "screenleave") {
        if (_o2.share_video == scene_state.screen_url) {
          scene_state.screen_url = "";
          _o2.share_video = "";
          check_scene_state();
        }
      }
    });
    //拖拽
    function on_drag_pic(show, res) {
      console.log("on_drag_pic", show, res);
      if (show.publish_preview) {
        let str = prompt(
          "已经布置了展项，是覆盖还是增加多张素材？0=覆盖 1=多素材 2=撤展",
          "1"
        );
        if (str == "0") {
          show.object.material = new THREE.MeshBasicMaterial();
          show.object.material.map = _o2.create_map(res.oss_preview);
          let url =
            that.url + "/change_show.php?type=1&id=" + show.id + "&resid=" + res.id;
          let xhr = new XMLHttpRequest();
          xhr.open("get", url, true);
          xhr.responseType = "text";
          xhr.send();
        } else if (str == "1") {
          let url =
            that.url + "/change_show.php?type=2&id=" + show.id + "&resid=" + res.id;
          let xhr = new XMLHttpRequest();
          xhr.open("get", url, true);
          xhr.responseType = "text";
          xhr.send();
          console.log(url);
        } else {
          let url =
            that.url + "/change_show.php?type=3&id=" + show.id + "&resid=" + res.id;
          let xhr = new XMLHttpRequest();
          xhr.open("get", url, true);
          xhr.responseType = "text";
          xhr.send();

          show.object.material.map = show.object.material.oldmap;
          show.object.material.color.setRGB(1, 0.5, 0.5);
        }
      } else {
        // show.object.material = new THREE.MeshBasicMaterial();
        show.object.material.map = _o2.create_map(res.oss_preview);
        let url = that.url + "/change_show.php?type=1&id=" + show.id + "&resid=" + res.id;
        let xhr = new XMLHttpRequest();
        xhr.open("get", url, true);
        xhr.responseType = "text";
        xhr.send();
      }
    }
    Bus.$on("drag", (event2) => {
      event2.offsetX = event2.x;
      event2.offsetY = event2.y;
      let intersects = _o2.ray_test(event2);
      let found_show = null;
      if (intersects.length) {
        let intersection = intersects[0];
        let obj = intersection.object;
        for (let i = 0; i < all_shows.length; i++) {
          let show = all_shows[i];
          if (show.object == obj) {
            found_show = show;
            break;
          }
        }
      }
      if (!found_show) {
        this.$message.error("请将素材往展板上拖拽");
        return;
      }

      //判断拖拽权限
      {
        user_group = _this.$chat.user_group;
        user_power = _this.$chat.role.auth;
        if (user_power == undefined) user_power = 0;
        console.log(user_power);
        if (user_power < 5) {
          this.$message.error("只有管理员才能布展!");
          return;
        }
      }

      var event = event2.file;

      if (!event) return;

      this.$o2Function.upload(event).then((res) => {
        on_drag_pic(found_show, res);
      });
    });
    Bus.$on("progress", (p) => {
      this.flag = true;
      this.progress = Math.round(p * 100);

      if (p == 1) {
        this.flag = false;
        this.$message.success("上传成功");
      }
    });
    //主持人模式
    Bus.$on("admin_mode", (e) => {
      console.log(e, "主持人模式开关");
      that.bAdminView = e;

      if (admin_mode) {
        let str = "share_view," + e;
        if (ws) {
          that.$chat.ws.send(str);
        }
      }
    });

    var csm_cold = 0;
    //玩家信息
    var dicPlayerInfo;
    //chat pop
    var chat_pops = [];
    var gif_pops = [];
    //展板编辑
    var show_screen_played = false;
    var show_video_object;
    var _this = this;
    var bBoardEditMode = false;
    var all_shows = [];
    var bShowAdminMode = false; //开启素材审核模式
    var user_power = 0; //0=游客 1=普通用户 2=认证用户 3=企业用户 4=企业管理员 5=总管理员
    var user_status = 0; //用户状态
    var user_group = 0; //展位编组id
    //区域
    var current_region;
    var regions = [];
    var region_cold_down;
    //座位
    var bMoveFinish = false;
    var all_sits = [];
    var bTempSit = false;
    var pos_sit = new THREE.Vector3();
    var name_sit;
    var angle_sit = 0;
    var old_angle = 0;
    var current_rtc = 0;
    var sit_state;
    var bAllPlayerLoaded = false;
    //相机控制

    var current_stage = 0;
    ///主持人模式
    var old_screen_url;
    var admin_mode = false;
    var scene_state = {}; //场景状态
    var rtc_room_list = {};
    var rtc_sit_list = {};
    /////////////
    var current_room_screen = null;
    var current_screen_mat = null;
    var region_screen = {}; //房间屏幕
    var down_speed = 0;
    var colli;
    var _sitman;
    var colli_height = 400;
    var last_click_pos = new THREE.Vector2();
    var last_click_time;
    var bCtrlStop = false;
    let old_pos, old_eye;
    var touch_time;
    var last_case_id = 0;
    var ws;
    let target_angle = 0;
    // if (document.o2.dreamType == 0) {
    //   var ground_height = 0;
    // }
    var ground_height = 0;

    let last_clip_name;
    let posPlayer = new THREE.Vector3();
    let startPos = new THREE.Vector3();
    let endPos = new THREE.Vector3();
    let mouse = new THREE.Vector2();
    var viewHeight = this.viewHeight;
    var itemName = this.itemName;
    var floor = this.floor;
    var player_name = "";
    var room_id = 0;
    var ws_pos_cold_down = 0;
    var player_id = 0;
    var bLoginSuccess = false;
    var last_pos_send;
    var last_cam_send;
    var screen_touched = false;

    //举手
    var handMtl;
    var handNode;
    var dicSitHand = {};

    console.log(floor);

    //视角分享
    // var bAdminView = false; //跟随支持人
    var admin_old_pos;
    var admin_old_eye;
    var admin_target_pos = new THREE.Vector3();
    var admin_target_eye = new THREE.Vector3();
    var admin_move_time;
    var admin_angle_old;
    var admin_angle_new;
    var last_walk;

    const netCmd = that.$chat.connectInfo;

    var vip_sit_info = {};
    /////项目介绍板
    var project_urls = [];
    for (let i = 1; i <= 15; i++) {
      project_urls.push("https://o2vr.net/res/demos/" + i + ".jpg");
    }
    project_urls[2] = "https://o2vr.net/res/demos/xnkl.jpg";
    var projects_parent;
    var project_objects = [];

    var moveSpd = this.speed;
    if (!moveSpd) moveSpd = 3000;
    if (!viewHeight) {
      viewHeight = 2000;
    }
    if (!itemName) {
      itemName = "Item";
    }
    var _o2 = document.o2;
    const project_id = _o2.project_id;
    var bKeyCtrl = true;
    var bAutoMove = false;
    var targetAutoMove = new THREE.Vector3();

    var moveDir = new THREE.Vector3();
    var that = this;
    function onMouseDown(event) {
      if (!screen_touched) {
        screen_touched = true;
      }
      check_scene_state();
      touch_time = Date.now();
    }
    function Base64() {
      // private property
      var _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

      // public method for encoding
      this.encode = function (input) {
        var output = "";
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        var i = 0;
        input = _utf8_encode(input);
        while (i < input.length) {
          chr1 = input.charCodeAt(i++);
          chr2 = input.charCodeAt(i++);
          chr3 = input.charCodeAt(i++);
          enc1 = chr1 >> 2;
          enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
          enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
          enc4 = chr3 & 63;
          if (isNaN(chr2)) {
            enc3 = enc4 = 64;
          } else if (isNaN(chr3)) {
            enc4 = 64;
          }
          output =
            output +
            _keyStr.charAt(enc1) +
            _keyStr.charAt(enc2) +
            _keyStr.charAt(enc3) +
            _keyStr.charAt(enc4);
        }
        return output;
      };
      // public method for decoding
      this.decode = function (input) {
        var output = "";
        var chr1, chr2, chr3;
        var enc1, enc2, enc3, enc4;
        var i = 0;
        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
        while (i < input.length) {
          enc1 = _keyStr.indexOf(input.charAt(i++));
          enc2 = _keyStr.indexOf(input.charAt(i++));
          enc3 = _keyStr.indexOf(input.charAt(i++));
          enc4 = _keyStr.indexOf(input.charAt(i++));
          chr1 = (enc1 << 2) | (enc2 >> 4);
          chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
          chr3 = ((enc3 & 3) << 6) | enc4;
          output = output + String.fromCharCode(chr1);
          if (enc3 != 64) {
            output = output + String.fromCharCode(chr2);
          }
          if (enc4 != 64) {
            output = output + String.fromCharCode(chr3);
          }
        }
        output = _utf8_decode(output);
        return output;
      };

      // private method for UTF-8 encoding
      let _utf8_encode = function (string) {
        string = string.replace(/\r\n/g, "\n");
        var utftext = "";
        for (var n = 0; n < string.length; n++) {
          var c = string.charCodeAt(n);
          if (c < 128) {
            utftext += String.fromCharCode(c);
          } else if (c > 127 && c < 2048) {
            utftext += String.fromCharCode((c >> 6) | 192);
            utftext += String.fromCharCode((c & 63) | 128);
          } else {
            utftext += String.fromCharCode((c >> 12) | 224);
            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
            utftext += String.fromCharCode((c & 63) | 128);
          }
        }
        return utftext;
      };

      // private method for UTF-8 decoding
      let _utf8_decode = function (utftext) {
        var string = "";
        var i = 0;
        let c2 = 0;
        let c1 = 0;
        let c3 = 0;
        var c = (c1 = c2 = 0);
        while (i < utftext.length) {
          c = utftext.charCodeAt(i);
          if (c < 128) {
            string += String.fromCharCode(c);
            i++;
          } else if (c > 191 && c < 224) {
            c2 = utftext.charCodeAt(i + 1);
            string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
            i += 2;
          } else {
            c2 = utftext.charCodeAt(i + 1);
            c3 = utftext.charCodeAt(i + 2);
            string += String.fromCharCode(
              ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
            );
            i += 3;
          }
        }
        return string;
      };
    }
    function send_camera_to_server(bForce) {
      if (!ws || !bLoginSuccess) return;
      let str = "cam," + getView();
      if (str != last_cam_send) {
        console.log(str);
        last_cam_send = str;
        that.$chat.ws.send(str);
      } else {
        if (bForce) {
          console.log(str);
          last_cam_send = str;
          that.$chat.ws.send(str);
        }
      }
    }
    function wrapText(context, text, x, y, maxWidth, lineHeight) {
      let ln = "";
      let max_ln = 3;
      let lns = [];
      for (let i = 0; i < text.length; i++) {
        let cr = text[i];
        let ln_old = ln;
        ln = ln + cr;
        var metrics = context.measureText(ln);
        var testWidth = metrics.width;
        if (testWidth > maxWidth) {
          lns.push(ln);
          ln = "";
          if (lns.length == max_ln) {
            break;
          }
          // context.fillText(ln, x, y);
          // max_ln=max_ln-1;
          // if (max_ln==0) return;
          // y=y+lineHeight;
          // ln=cr;
        }
      }
      if (ln.length) {
        lns.push(ln);
        //      context.fillText(ln, x, y);
      }
      y = y - lns.length * 15;
      for (let i = 0; i < lns.length; i++) {
        context.fillText(lns[i], x, y);
        y = y + lineHeight;
      }
    }
    function gif_pop(char_obj, idx_gif) {
      let sample = _o2.gifs[idx_gif];
      if (!sample) return;
      let sprite = sample.clone();
      sprite.beginTime = Date.now();
      sprite.position.set(0, 2200, 0);
      char_obj.add(sprite);
      gif_pops.push(sprite);
    }
    _o2.gif_pop = gif_pop;

    function chat_pop(char_obj, text, high = 2200) {
      if (!text.length) return;
      let old_item;
      let old_idx = -1;
      for (let i = 0; i < chat_pops.length; i++) {
        let chat = chat_pops[i];
        if (chat.parent == char_obj) {
          old_item = chat;
          old_idx = i;
          break;
        }
      }
      if (old_item) {
        //删掉
        old_item.parent.remove(old_item);
      }
      let image = new Image();
      image.src = require("../assets/img/pop.png");
      image.onload = function () {
        let canvas = document.createElement("canvas");
        canvas.width = 256;
        canvas.height = 512;
        const drawingContext = canvas.getContext("2d");
        drawingContext.fillStyle = "#000000";
        drawingContext.globalAlpha = 0.8;
        drawingContext.drawImage(this, 0, 0);
        drawingContext.globalAlpha = 1;
        drawingContext.fillStyle = "#000000";
        drawingContext.font = "20px Georgia";
        drawingContext.textAlign = "center";
        wrapText(drawingContext, text, 140, 140, 160, 24);
        //drawingContext.fillText("这是一大长串文字，不知道能不能自动换行啥的", 128, 128);

        let map = new THREE.CanvasTexture(canvas);
        let sprite = new THREE.Sprite(
          new THREE.SpriteMaterial({ map: map, color: "#ffffff" })
        );
        sprite.position.set(0, high, 0);
        sprite.scale.set(1000, 2000, 1);
        sprite.depthWrite = false;
        char_obj.add(sprite);
        sprite.beginTime = Date.now();
        sprite.canvas = canvas;
        sprite.alphaTest = 0.01;
        if (old_idx >= 0) {
          chat_pops[old_idx] = sprite;
        } else chat_pops.push(sprite);
      };
    }
    _o2.chat_pop = chat_pop;
    function send_position_to_server(bForce) {
      if (!that.$chat.ws || !bLoginSuccess || !_o2.role || _o2.role.vip < 3) return;
      let angle2 = Math.ceil((target_angle * 180) / 3.1415);
      let buffer = new ArrayBuffer(10);
      let data = new DataView(buffer);
      data.setInt8(0, 191);
      data.setInt8(1, 4);
      data.setInt16(2, Math.ceil(posPlayer.x / 100), true);
      data.setInt16(4, Math.ceil(posPlayer.y / 100), true);
      data.setInt16(6, Math.ceil(posPlayer.z / 100), true);
      data.setInt16(8, angle2, true);
      let str =
        Math.ceil(posPlayer.x) +
        "," +
        Math.ceil(posPlayer.y) +
        "," +
        Math.ceil(posPlayer.z) +
        "," +
        angle2;
      if (str != last_pos_send) {
        //console.log(str);
        last_pos_send = str;
        that.$chat.ws.send(data);
      } else {
        if (bForce) {
          console.log(str);
          last_pos_send = str;
          that.$chat.ws.send(data);
        }
      }
    }

    function stop_ctrl(bStop) {
      if (bCtrlStop == bStop) return;
      if (!bCtrlStop) {
        old_pos = _o2.cameraControls.target.clone();
        old_eye = _o2.camera.position.clone();
      } else {
        _o2.cameraControls.target.set(old_pos.x, old_pos.y, old_pos.z);
        _o2.camera.position.set(old_eye.x, old_eye.y, old_eye.z);
      }
      bCtrlStop = bStop;
    }
    _o2.stop_ctrl = stop_ctrl;

    function onItemClick(intersect) {
      //播放视频
      {
        let objName = intersect.object.name;
        if (objName == "展板_02" || objName == "展板_321") {
          if (show_screen_played) {
            show_screen_played = false;
            //播放视频
            if (show_video_object) {
              if (show_video_object.oldmap) {
                show_video_object.material.map = show_video_object.oldmap;
                show_video_object.material.lightMap = show_video_object.oldlightmap;
              }
              _o2.stop_video_tex();
            }
            if (show_video_object == intersect.object) return;
          }
          if (intersect.object.sw) {
            console.log('ponit');
            Bus.$emit("point", intersect.object.sw);
            // let tex = _o2.get_video_tex(intersect.object.sw.publish_url, true);
            // if (tex) {
            //   intersect.object.oldmap = intersect.object.material.map;
            //   intersect.object.oldlightmap = intersect.object.material.lightMap;
            //   intersect.object.material.map = tex;
            //   intersect.object.material.lightMap = _o2.map_white;
            //   show_screen_played = true;
            //   show_video_object = intersect.object;
            // }
          }
          return;
        }
      }

      Bus.$emit("梦界空间点击", intersect.object.name);

      // if (intersect.object.name=="screen") {
      //   if (scene_state.screen_url)
      //   {
      //     old_screen_url=scene_state.screen_url;
      //     _o2.screen.visible=true;
      //     _o2.logo.visible=false;
      //     Bus.$emit('playVideo',scene_state.screen_url);
      //   }
      // }
      console.log("onItemClick", intersect);
      if (bShowAdminMode || bBoardEditMode) return;
      //行走

      move_to(intersect.point.x, intersect.point.z);
    }
    function send_click_msg() {
      if (!ws || !bLoginSuccess) return;
      if (!admin_mode) return;
      let str_pos =
        "click," +
        Math.round(_o2.click.position.x) +
        "," +
        Math.round(_o2.click.position.y) +
        "," +
        Math.round(_o2.click.position.z);
      that.$chat.ws.send(str_pos);
    }
    function getMovePoint(intersect) {
      if (that.flag) return;
      let _o2 = document.o2;
      let pos = intersect.position;
      return pos;
    }
    function on_db_click(event) {
      return;
      // if (bBoardEditMode) {
      //   let intersects = _o2.ray_test(event);
      //   if (intersects.length > 0 && current_region) {
      //     bAutoMove = false;
      //     let intersection = intersects[0];
      //     let position = intersection.point.clone();
      //     let obj = intersection.object;
      //     let str = 'region_screen["' + current_region.name + '"]="' + obj.uuid + '";';
      //     console.log(str);
      //   }
      //   return;
      // }
      if (bBoardEditMode) {
        let intersects = _o2.ray_test(event);
        if (intersects.length > 0) {
          bAutoMove = false;
          let intersection = intersects[0];
          let position = intersection.point.clone();
          //移动到物体表面来
          let nor = intersection.face.normal.clone();
          nor.applyEuler(intersection.object.rotation);
          nor.multiplyScalar(100);
          position.add(nor);
          console.log(intersection.object);
          let str = prompt("输入group_id", current_stage);
          if (str && str != "") {
            intersection.object.material.map = _o2.map_red;

            let url =
              that.url +
              "/add_board.php?uuid=" +
              intersection.object.uuid +
              "&group=" +
              str;
            let xhr = new XMLHttpRequest();
            xhr.open("get", url, true);
            xhr.responseType = "text";
            xhr.send();

            add_lable_to_sit(
              "【展板】公司id=" + str,
              position.x,
              position.y - 800,
              position.z
            );
          }
        }
        return;
      }
      // 坐下;
      let intersects = _o2.ray_test(event);
      if (intersects.length > 0) {
        let intersect = intersects[0];
        bTempSit = true;
        _o2.role.setState("sit");
        pos_sit = intersect.point.clone();
        pos_sit.y = pos_sit.y - 460;
      }
    }

    function onMouseUp(event) {
      // if (that.flag) return;
      let _o2 = document.o2;

      let dnow = Date.now();
      let dst = dnow - touch_time;
      if (dst > 200) {
        return;
      }
      //双击判断
      {
        if (last_click_time) {
          let offset = new THREE.Vector2();
          offset.set(event.offsetX, event.offsetY);
          let dst2 = dnow - last_click_time;
          offset.sub(last_click_pos);
          let len = offset.length();
          console.log("dbtest,", len, dst2);
          if (len < 100 && dst2 < 500) {
            on_db_click(event);
            last_click_time = null;
            return;
          }
        }
      }
      last_click_pos.set(event.offsetX, event.offsetY);
      last_click_time = dnow;
      let intersects = _o2.ray_test(event);
      if (intersects.length > 0) {
        onItemClick(intersects[0]);
      }
    }
    function init_player_char(obj, arr) {
      if (obj) {
        for (let i = 0; i < obj.children.length; i++) {
          let child = obj.children[i];
          if (child.name.indexOf("Hair") >= 0 && arr[0] != "undefined") {
            let idx = parseInt(child.name.substr(5, child.name.length - 5));
            child.visible = idx == arr[0];
          }
          if (child.name.indexOf("Glasses") >= 0 && arr[1] != "undefined") {
            let idx = parseInt(child.name.substr(8, child.name.length - 8));
            child.visible = idx == arr[1];
          }
        }
      }
    }
    function on_ws_click(str) {
      var postr = str.split(",");
      if (_o2.click) {
        _o2.click.position.set(
          parseInt(postr[0]),
          parseInt(postr[1]),
          parseInt(postr[2])
        );
        _o2.click.visible = true;
        _o2.click.click_life = 3;
      }
    }
    function setView(str) {
      var view = str.split(",");
      admin_old_eye = _o2.camera.position.clone();
      if (!admin_old_pos)
        posPlayer.set(parseInt(view[3]), parseInt(view[4]), parseInt(view[5]));
      admin_old_pos = posPlayer.clone();
      if (!admin_target_pos) admin_target_pos = new THREE.Vector3();
      if (!admin_target_eye) admin_target_eye = new THREE.Vector3();
      admin_move_time = Date.now();
      admin_target_eye.set(parseInt(view[0]), parseInt(view[1]), parseInt(view[2]));
      admin_target_pos.set(parseInt(view[3]), parseInt(view[4]), parseInt(view[5]));
      admin_angle_old = _o2.role.obj.rotation.y;
      admin_angle_new = (parseInt(view[6]) / 180) * 3.1416;

      let dst = (admin_angle_new * 180) / 3.1415 - (admin_angle_old * 180) / 3.1415;
      if (Math.abs(dst) > 180) {
        if (dst < 0) admin_angle_old = admin_angle_old - 3.1415 * 2;
        else admin_angle_old = admin_angle_old + 3.1415 * 2;
      }
    }
    function getView() {
      return (
        Math.round(_o2.camera.position.x) +
        "," +
        Math.round(_o2.camera.position.y) +
        "," +
        Math.round(_o2.camera.position.z) +
        "," +
        Math.round(posPlayer.x) +
        "," +
        Math.round(posPlayer.y) +
        "," +
        Math.round(posPlayer.z) +
        "," +
        Math.round((_o2.role.obj.rotation.y * 180) / 3.1416)
      );
    }

    function syncPlayerState(id) {
      if (sit_state) {
        let p = _o2.playerMgr.getPlayerById(id);
        let sitState = sit_state[id];
        if (p && sitState) {
        }
      }
    }

    function initPlayer(p, name) {
      if (name) p.add_lable_to_char(name, 2000);
      p.onStateChg.push((state) => {
        if (p.namePanel) {
          p.namePanel.position.y = state == "sit" ? 1600 : 2000;
          if (!p.bLoad) {
            p.obj.visible = state == "sit";
          }
        }
      });
      if (p && p.bLoad) {
        if (_o2.originPos) {
          let arr = _o2.originPos;

          //setRolePos(arr[0], arr[1], arr[2]);
          p.obj.position.set(arr[0], arr[1], arr[2]);
          //p.obj.rotation.set(0, arr[6], 0);
        }
      }
    }

    function setAllPlayerSit() {
      if (sit_state && bAllPlayerLoaded) {
        for (const key in sit_state) {
          //_o2.sit_man(1, key, sit_state[key]);
          let id = parseInt(sit_state[key]);
          let p = _o2.playerMgr.getPlayerById(id);
          if (p) {
            p.setSit(key);
          } else {
            addFakePlayer(id, key);
          }
        }
      }
    }

    function addFakePlayer(id, sitName) {
      let modelIdx = Math.floor(Math.random() * 6);
      _o2.playerMgr
        .import_player({
          id: id,
          bFake: true,
          info: { modelIdx: modelIdx },
        })
        .then((p) => {
          initPlayer(p);
          p.setSit(sitName);
        });
    }

    function add_player(info) {
      //console.log(info);
      if (info) {
        let id = parseInt(info.id);
        let player = _o2.playerMgr.getPlayerById(id);
        if (!player) {
          let bLoad = info.vip >= 3;
          _o2.playerMgr.import_player(info).then((p) => {
            if (p.obj) p.obj.visible = false;
            initPlayer(p, info.name);
            if (sit_state) {
              for (const key in sit_state) {
                if (id == parseInt(sit_state[key])) {
                  p.setSit(key);
                }
              }
            }
          });
        }
      }
    }

    function update_player_position(id_player, posx, posy, posz, angle) {
      let player = _o2.playerMgr.getPlayerById(id_player);
      if (player && player != _o2.role && player.obj) {
        player.target.x = posx;
        player.target.z = posz;
        player.target.y = posy;
        player.targetAngle = (angle * 3.1415) / 180;
        if (player.obj && !player.obj.visible) {
          player.obj.visible = true;
          player.obj.position.set(posx, posy, posz);
        }
      }
    }
    function setRolePos(x, y, z, xx, yy, zz) {
      console.log("setRolePos");
      if (_o2.role && _o2.role.obj) {
        if (xx && yy && zz) {
          let rolePos = _o2.role.obj.position;
          _o2.camera.position.set(
            rolePos.x + xx - x,
            rolePos.y + yy - y,
            rolePos.z + zz - z
          );
        }
        let dir = new THREE.Vector3(x - xx, y - yy, z - zz);
        dir.y = 0;
        dir.normalize();
        old_angle = Math.atan2(dir.x, dir.z);
        _o2.role.obj.rotation.y = old_angle;
        posPlayer.set(x, y, z);
        _o2.role.obj.position.set(x, y, z);
      }
    }
    _o2.setRolePos = setRolePos;

    function move_to(x, y) {
      bAutoMove = true;
      targetAutoMove.x = x;
      targetAutoMove.y = ground_height;
      targetAutoMove.z = y;
    }
    function remove_player(id) {
      if (_o2.players) {
        let left_players = [];
        for (let i = 0; i < _o2.players.length; i++) {
          let player = _o2.players[i];
          if (player.id == id) {
            player.destroy();
          } else {
            left_players.push(player);
          }
        }
        _o2.players = left_players;
      }
    }

    function prepare_cases() {
      var xhr = new XMLHttpRequest();
      xhr.open("get", "https://o2vr.net/res/cases/case.json", true);
      xhr.responseType = "text";
      xhr.onload = function () {
        if (this.status == 200) {
          var xxx = JSON.parse(this.response);
          console.log(xxx);

          //创建DEMO列表板
          for (let i = 0; i < xxx.length; i++) {
            let obj = xxx[i];
            let url = "https://o2vr.net/res/cases/" + obj.img;
            let map = _o2.create_map(url);
            if (map) {
              var geometry = new THREE.PlaneGeometry(1600, 900);
              var material = new THREE.MeshPhongMaterial({
                color: 0xffffff,
                side: THREE.DoubleSide,
              });
              material.map = map;
              material.lightMap = _o2.map_white;
              var plane = new THREE.Mesh(geometry, material);
              let dz = Math.random() * 2000;
              let dx = Math.random() * 1000 - 800;
              dz = dx = 0;
              dz = 300;
              plane.position.set(dx, -i * 2000, -4023 + dz);
              projects_parent.add(plane);
              let scale = Math.random() * 0.5 + 1;
              scale = 2;
              plane.scale.set(scale, scale, scale);
              project_objects.push(plane);
              plane.case_url = url;
              plane.case_id = i + 1;
              plane.case = obj;
              plane.basey = plane.position.y;
            }
          }
        }
      };
      xhr.send();
    }
    _o2.move_to = move_to;
    function init() {
      current_stage = localStorage.getItem("olytype")
        ? localStorage.getItem("olytype")
        : 0;

      //注册语音房间
      if (current_stage >= 2) {
        for (let i = 1; i < 7; i++) {
          for (let j = 1; j < 5; j++) {
            let namertc = "r" + i + "-" + j;
            rtc_room_list[namertc] = 1000 * current_stage + i * 10 + j;
          }
        }
      }
      _o2.stop_switch_camera();
      projects_parent = new THREE.Object3D();
      _o2.scene.add(projects_parent);
      colli = _o2.search_obj_by_name("colli");
      _sitman = _o2.search_obj_by_name("sit_man");

      _o2.csm =
        _o2.dreamType == 0
          ? _o2.search_obj_by_name("传送门")
          : _o2.search_obj_by_name("传送门_1");
      let region = _o2.search_obj_by_name("region");
      if (region) {
        regions = region.children;
        regions.forEach((e) => {
          e.matrixWorld = e.matrix.multiply(region.matrix);
          e.matrixWorldInverse = e.matrixWorld.clone().invert();
        });
      }
      //vip座椅
      const vip_sit_id = {
        椅子01: "V-02",
        椅子02: "V-01",
        椅子03: "V-04",
        椅子04: "V-03",
        椅子05: "V-06",
        椅子06: "V-05",
        椅子07: "V-08",
        椅子08: "V-07",
        椅子09: "V-10",
        椅子10: "V-09",
        椅子11: "V-12",
        椅子12: "V-11",
        椅子13: "V-14",
        椅子14: "V-13",
        椅子15: "V-16",
        椅子16: "V-15",
        椅子17: "V-18",
        椅子18: "V-17",
        椅子19: "V-20",
        椅子20: "V-19",
      };
      for (const key in vip_sit_id) {
        vip_sit_info[key] = {};
        vip_sit_info[key].id = vip_sit_id[key];
        vip_sit_info[key].obj = _o2.search_obj_by_name(key);
      }
      //获取玩家座位状态
      // let sit_state_url = "https://api.wh12345.net:9300/sits";
      // axios.get(sit_state_url).then((res) => {
      //   console.log("sit_state:" + res.data);
      //   sit_state = res.data;
      // });
      /////
      //获取座位
      let url_sits = that.url + "/get_all_sits.php";
      let xhr = new XMLHttpRequest();
      xhr.open("get", url_sits, true);
      xhr.responseType = "text";
      xhr.send();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status >= 200 && xhr.status < 300) {
            var xxx = xhr.response;
            let sits = JSON.parse(xxx);
            all_sits = sits;
            let sits2 = [];
            for (let i = 0; i < all_sits.length; i++) {
              let sit = all_sits[i];
              sit.pos = new THREE.Vector3();
              sit.pos.set(Number(sit.x), Number(sit.y), Number(sit.z));
              sit.angle = Number(sit.angle);
              let idx = parseInt(sit.name.substr(0, 1));
              // if (idx != current_stage) {
              //   continue;
              // }
              sits2.push(sit);
              // add_lable_to_sit(sit.name, sit.pos.x, sit.pos.y + 1000, sit.pos.z);
            }
            all_sits = sits2;
          }
        }
      };
    }

    function get_sit(sit_name) {
      for (let i = 0; i < all_sits.length; i++) {
        let sit = all_sits[i];
        if (sit.name == sit_name) return sit;
      }
      return null;
    }
    _o2.get_sit = get_sit;

    Bus.$on("loginS", () => {
      /////
      //获取展板
      console.log(_this.$chat);
      user_power = _this.$chat.user_power;
      user_group = _this.$chat.user_group;
      if (user_power == undefined) user_power = 0;

      console.log("所有在场用户", that.$chat.playerList);
      let url_shows = that.url + "/get_all_show.php?room_id=0";
      console.log(url_shows);
      let xhr2 = new XMLHttpRequest();
      xhr2.open("get", url_shows, true);
      xhr2.responseType = "text";
      xhr2.send();
      xhr2.onreadystatechange = () => {
        if (xhr2.readyState === 4) {
          if (xhr2.status >= 200 && xhr2.status < 300) {
            var xxx = xhr2.response;
            let sits = JSON.parse(xxx);

            console.log("所有展项", sits);
            all_shows = sits;
            for (let i = 0; i < all_shows.length; i++) {
              let sw = all_shows[i];
              sw.object = _o2.search_obj_by_guid2(sw.model_guid);
              if (sw.object && sw.object.material) {
                if (sw.publish_preview) {
                  sw.object.material.oldmap = sw.object.material.map;
                  sw.object.material.map = _o2.create_map(sw.publish_preview);
                  sw.object.material.color.setRGB(1, 1, 1);
                  sw.object.sw = sw;
                } else {
                  console.log("未布展", sw);
                  sw.object.material.color.setRGB(1, 0.5, 0.5);
                }
              }
            }
            console.log(all_shows);
          }
        }
      };
    });

    function sit_man(bsit, sitname, id_player) {
      //假人
      let p = _o2.playerMgr.getPlayerById(parseInt(id_player));
      if (p) return; //骨骼角色不处理
      if (!_o2.all_players) return;
      console.log("sitman", bsit, sitname, id_player);
      let found_man;
      for (let i = 0; i < _o2.all_players.length; i++) {
        let man2 = _o2.all_players[i];
        if (man2.id == id_player) {
          found_man = man2;
          break;
        }
      }
      if (found_man) {
        let vip = parseInt(found_man.vip);
        if (vip >= 3) return;
      }
      let found_sit;

      for (let i = 0; i < all_sits.length; i++) {
        let sit = all_sits[i];
        if (sit.name == sitname) {
          found_sit = sit;
          break;
        }
      }
      if (!found_sit) return;
      let name = "";
      if (found_man) name = found_man.name;
      if (bsit) {
        //坐下
        new Promise((resolve, reject) => {
          if (player.cfg) {
            let cfg = player.cfg[project_id];
            if (cfg) {
              let idx = Math.random() * cfg.sitModels.length;
              idx = Math.floor(idx);
              if (player.dicSitModel[idx]) {
                resolve(player.dicSitModel[idx]);
              } else {
                let url = cfg.sitModels[idx];
                _o2.import_object_url(url).then((obj) => {
                  let sitModel = new THREE.Object3D(obj.name);
                  sitModel.add(obj);
                  player.dicSitModel[idx] = sitModel;
                  resolve(sitModel);
                });
              }
            }
          } else {
            reject();
          }
        }).then((model) => {
          if (model) {
            let newman = model.clone();
            newman.visible = true;
            newman.position.set(found_sit.pos.x, found_sit.pos.y, found_sit.pos.z);
            newman.rotation.y = (found_sit.angle * 3.1416) / 180;
            if (name != "") {
              let sp = _o2.add_lable_to_char(newman, name);
              sp.position.y = 1000;
            }
            _o2.scene.add(newman);
            found_sit.dummy = newman;
          }
        });
      } else {
        //起立
        if (found_sit.dummy) {
          _o2.scene.remove(found_sit.dummy);
          found_sit.dummy = null;
        }
      }
    }
    _o2.sit_man = sit_man;
    function move_with_colli(target, time_passed) {
      if (!colli) {
        posPlayer.set(target.x, target.y, target.z);
        return;
      }
      let radius = 400;
      let final = posPlayer.clone();
      let org = posPlayer.clone();
      org.y += colli_height;
      let target2 = target.clone();
      target2.y += colli_height;
      let dst = target2.clone();
      dst.sub(org);
      let len = dst.length() + radius;
      let raycaster = new THREE.Raycaster();
      raycaster.far = 1000;
      let dir = dst.clone();
      dir.normalize();
      org.sub(dir);
      raycaster.set(org, dir);
      var intersects = raycaster.intersectObjects(colli.children, true);
      if (intersects.length > 0) {
        if (intersects[0].distance < len) {
          dir.multiplyScalar(intersects[0].distance - radius);
          final.add(dir); //现在是退回到被拦截的位置，下面需要从切线方向滑行
          //滑行碰撞检测
          dir.normalize();
          let normal = intersects[0].face.normal.clone();
          normal.applyEuler(intersects[0].object.rotation);
          let spit = new THREE.Vector3(0, 1, 0);
          spit.cross(normal);
          spit.normalize();
          if (spit.dot(dir) < 0) spit = spit.multiplyScalar(-1); //旋转方向至行走方向的一边
          let len1 = dst.dot(spit);
          let len_final = len1 + radius;
          raycaster.set(org, spit);
          let intersects3 = raycaster.intersectObjects(colli.children, true);
          if (intersects3.length) {
            if (intersects[0].distance < len_final) {
              spit.multiplyScalar(intersects[0].distance - radius);
              final.add(spit);
            } else {
              spit.multiplyScalar(len_final - radius);
              final.add(spit);
            }
          } else {
            spit.multiplyScalar(len_final - radius);
            final.add(spit);
          }
        } else {
          final.set(target.x, posPlayer.y, target.z);
        }
      } else {
        final.set(target.x, posPlayer.y, target.z);
      }

      //下落
      down_speed = down_speed + 9800 * time_passed;
      let down_length = down_speed * time_passed;
      org.set(org.x, posPlayer.y + 500, org.z);
      dir.set(0, -1, 0);
      let y = posPlayer.y - down_length;
      let raycaster2 = new THREE.Raycaster();
      raycaster2.far = 1000;
      raycaster2.set(org, dir);
      intersects = raycaster2.intersectObjects(colli.children, true);
      if (intersects.length > 0) {
        //有碰撞到
        let pos = intersects[0].point;
        //console.log("地面高度:" + pos.y);
        if (intersects[0].distance < 500 + down_length) {
          down_speed = 0;
          y = pos.y;
          let dst = pos.y - posPlayer.y;
          if (dst < colli_height) {
            if (pos.y > posPlayer.y) {
              y = posPlayer.y * 0.5 + pos.y * 0.5;
            } else {
              y = pos.y;
            }
          }
        }
      }
      if (y < ground_height) {
        //落到了0平面
        y = ground_height;
        down_speed = 0;
      }
      final.y = y;
      posPlayer.set(final.x, final.y, final.z);
      //console.log("地面高度:" + final.y);
    }
    function check_scene_state() {
      console.log("check_state");
      if (!screen_touched) return;
      if (scene_state.screen_url != old_screen_url) {
        old_screen_url = scene_state.screen_url;
        let value = scene_state.screen_url ? true : false;
        _o2.screen.visible = value;
        //_o2.logo.visible = !value;
        // _o2.bloomPass.strength = 0;
        Bus.$emit("playVideo", scene_state.screen_url);
      }
    }

    function on_ws_state(state) {
      for (const key in state) {
        scene_state[key] = state[key];
      }
      check_scene_state();
    }

    _o2.init_play = init;

    if ("WebSocket" in window) {
      Bus.$on("playerList", (e) => {
        console.log("素有用户", e);
        _o2.all_players = e;
      });
      Bus.$on("playermsg", function (evt) {
        var received_msg = evt.data;
        if (received_msg instanceof Blob) {
          received_msg.arrayBuffer().then((buffer) => {
            let dataView = new DataView(buffer);
            let head = dataView.getUint8(0); //头 0xBF
            if (head == 0xbf) {
              let cmd = dataView.getUint8(1); //命令
              if (cmd == netCmd.MSG_POS) {
                let id = dataView.getUint16(2, true); //id
                let x = dataView.getInt16(4, true) * 100; //x
                let y = dataView.getInt16(6, true) * 100; //y
                let z = dataView.getInt16(8, true) * 100; //z
                let rot = dataView.getInt16(10, true); //rot
                update_player_position(id, x, y, z, rot);
              } else if (cmd == netCmd.MSG_BUF_POS) {
                let lens = dataView.getUint16(2, true); //lens
                for (let i = 0; i < lens; i++) {
                  let id = dataView.getUint16(i * 10 + 4, true); //id
                  let x = dataView.getInt16(i * 10 + 6, true) * 100; //x
                  let y = dataView.getInt16(i * 10 + 8, true) * 100; //y
                  let z = dataView.getInt16(i * 10 + 10, true) * 100; //z
                  let rot = dataView.getInt16(i * 10 + 12, true); //rot
                  update_player_position(id, x, y, z, rot);
                }
              }
            }
          });
          return;
        }
        // console.log(received_msg);
        let pos = received_msg.indexOf(",");
        if (pos > 0) {
          let cmd = received_msg.substr(0, pos);
          let param = received_msg.substr(pos + 1, received_msg.length - pos - 1);
          if (cmd == netCmd.MSG_LOGIN_SUCCESS) {
            player_id = parseInt(param);
            let name = that.$chat.role.name;
            let vip = parseInt(that.$chat.role.auth);
            let modelIdx = parseInt(that.$chat.role.model_id);
            modelIdx = Number.isNaN(modelIdx) ? 0 : modelIdx;
            _o2.playerMgr
              .import_player({
                id: player_id,
                name: name,
                vip: vip,
                info: { modelIdx: modelIdx },
              })
              .then((p) => {
                _o2.role = p;
                _o2.role.vip = vip;
                _o2.player = p.obj;
                _o2.playerMgr.current_player = p;
                p.obj.traverse((c) => {
                  if (c.type == "SkinnedMesh") {
                    c.frustumCulled = false;
                  }
                });
                initPlayer(p, name);
                if (_o2.originPos) {
                  let arr = _o2.originPos;
                  setRolePos(arr[0], arr[1], arr[2], arr[3], arr[4], arr[5]);
                }
                Bus.$emit("onRoleLoaded", p);
                bLoginSuccess = true;
              });
          }
          if (cmd == netCmd.MSG_PLAYERS) {
            let arr = JSON.parse(param);
            if (arr && arr.length > 0) {
              arr.forEach((e) => {
                let data = JSON.parse(e);
                let id = parseInt(data.id);
                let vip = parseInt(data.vip);
                vip = Number.isNaN(vip) ? 0 : vip;
                let modelIdx = parseInt(data.model_id);
                modelIdx = Number.isNaN(modelIdx) ? 0 : modelIdx;
                // if (Number.isNaN(vip)) vip = 0;
                if (id != player_id) {
                  let info = {};
                  info.id = id;
                  info.info = { modelIdx: modelIdx };
                  info.vip = vip;
                  info.name = data.name;
                  info.bLoad = vip >= 3;
                  add_player(info);
                }
              });
            }
            bAllPlayerLoaded = true;
            setAllPlayerSit();
          }
          if (cmd == netCmd.MSG_SITS) {
            sit_state = JSON.parse(param);
            setAllPlayerSit();
          }
          if (cmd == 213) {
            //scene state
            let state = JSON.parse(param);
            on_ws_state(state);
          }
          //座位坐下成功
          if (cmd == netCmd.MSG_SIT_DOWN) {
            var temp = param.split(",");
            let sitName = temp[0];
            if (sitName == "refuse") return;
            let id = Number(temp[1]);
            let sit = get_sit(sitName);
            if (_o2.role && id == _o2.role.id) {
              //let sit = get_sit(temp[1]);
              if (sit) {
                let rtc_id = rtc_sit_list[sit.name];
                if (rtc_id > 0) {
                  current_rtc = rtc_id;
                  Bus.$emit("clientLogin", rtc_id);
                }
                pos_sit = sit.pos.clone();
                angle_sit = (sit.angle * 3.1416) / 180;
                name_sit = sit.name;
                _o2.role.setSit(name_sit);
                bTempSit = true;
              }
            } else {
              let curPlayer = _o2.playerMgr.getPlayerById(id);
              if (curPlayer) {
                curPlayer.setSit(sitName);
                //根据 id 判断
              } else {
                addFakePlayer(id, sitName);
              }
              //if (id != undefined) {
              //   //都是假人
              //   _o2.sit_man(1, sitName, id);
              //   console.log("假人", id);
              // }
            }
          } else if (cmd == netCmd.MSG_CLEAR_SITDOWNS) {
            remove_all_hand();
            if (_o2.role) _o2.role.bHand = false;
            bTempSit = false;
            let allPlayer = _o2.playerMgr.dicPlayer;
            if (allPlayer) {
              for (const key in allPlayer) {
                let p = allPlayer[key];
                if (p.bFake) {
                  _o2.playerMgr.removePlayer(p.id);
                } else {
                  p.setSit();
                }
              }
            }
            // _o2.playerMgr.dicPlayer
            // _o2.playerMgr.arrPlayer.forEach((e) => {
            //   e.setSit();
            // });
            // all_sits.forEach((e) => {
            //   if (e.dummy) {
            //     _o2.scene.remove(e.dummy);
            //     e.dummy = null;
            //   }
            // });
          } else if (cmd == netCmd.MSG_STAND_UP) {
            //console.log("up" ,received_msg);
            Bus.$emit("sit", false);
            let arrStr = param.split(",");
            let sitname = arrStr[0];
            remove_hand_to_sit(sitname);
            let id = arrStr[1];
            //let name = arrStr[3];
            let curPlayer = _o2.playerMgr.getPlayerById(id);
            if (curPlayer) {
              if (curPlayer.bFake) {
                _o2.playerMgr.removePlayer(curPlayer.id);
              } else {
                curPlayer.setSit();
              }
            } else {
              _o2.sit_man(0, arrStr[0], arrStr[1]);
            }
          } else if (cmd == netCmd.MSG_NEWPLAYER) {
            let info = {};
            info.info = { modelIdx: 0 };
            let arr = param.split(",");
            if (arr) {
              if (arr.length >= 1) info.id = parseInt(arr[0]);
              if (arr.length >= 2) info.name = arr[1];
              if (arr.length >= 3) info.vip = parseInt(arr[2]);
              let modelIdx = parseInt(arr[arr.length-1]);
              modelIdx = Number.isNaN(modelIdx) ? 0 : modelIdx;
              info.info.modelIdx = modelIdx;
              info.vip = Number.isNaN(info.vip) ? 0 : info.vip;
              info.bLoad = info.vip >= 3;
              add_player(info);
            }
          } else if (cmd == netCmd.MSG_QUIT) {
            let id = parseInt(param);
            let p = _o2.playerMgr.getPlayerById(id);
            if (p) {
              _o2.playerMgr.removePlayer(id);
              if (p.sit) remove_hand_to_sit(p.sit);
            }

            Bus.$emit("num", -1);
          } else if (cmd == netCmd.MSG_USER_INFO) {
            let value = parseInt(param);
            if (value) {
              let msg = netCmd.MSG_CUSTOM + ",";
              let data = { msg: "setPlayer", data: _o2.role.info };
              msg += JSON.stringify(data);
              that.$chat.ws.send(msg);
            }
          } else if (cmd == netCmd.MSG_CHATS) {
            let arrStr = param.split(",");
            if (arrStr && arrStr.length == 2) {
              let id = parseInt(arrStr[0]);
              let msg = arrStr[1];
              let player = _o2.playerMgr.getPlayerById(id);
              if (player && player.obj) {
                let high = player.sit ? 1700 : 2200;
                _o2.chat_pop(player.obj, msg, high);
              }
            }
          } else if (cmd == netCmd.MSG_ITEM_DISPLAY) {
            let arr = param.split(",");
            if (arr) {
              for (let i = 0; i < arr.length; i += 2) {
                let name = arr[i];
                let value = parseInt(arr[i + 1]);
                if (vip_sit_info && vip_sit_info[name]) {
                  let obj = vip_sit_info[name].obj;
                  if (obj) obj.visible = value ? true : false;
                }
              }
            }
          } else if (cmd == netCmd.MSG_CUSTOM) {
            let pos = param.indexOf(",");
            if (pos > 0) {
              let id = param.substr(0, pos);
              id = parseInt(id);
              let p = _o2.playerMgr.getPlayerById(id);
              param = param.substr(pos + 1, param.length - pos - 1);
              if (param.substr(0, 3) == "gif") {
                //表情动画播放
                let idx = parseInt(param.substr(-1, 1));
                if (p) {
                  if (p.obj) {
                    _o2.gif_pop(p.obj, idx);
                  }
                }
                return;
              }

              let json;
              try {
                json = JSON.parse(param);
              } catch (e) {
                console.log(param + " is not json");
              }
              if (json) {
                if (p && p[json.msg]) {
                  p[json.msg](json.data);
                } else if (json.msg == "handDown") {
                  if (id == _o2.role.id) {
                    _o2.role.bHand = false;
                  }
                  //let sit = json.data;
                  remove_hand_to_sit(json.data);
                  Bus.$emit("shenqing1", {
                    id: id,
                    name: that.$chat.searchName(id),
                    zw: json.data,
                  });
                } else if (json.msg == "handUp") {
                  if (id == _o2.role.id) {
                    _o2.role.bHand = true;
                  }
                  add_hand_to_sit(json.data);
                  Bus.$emit("shenqing", {
                    name: that.$chat.searchName(id),
                    id: id,
                    zw: json.data,
                  });
                } else if (json.msg == "zhuchiren") {
                  if (json.id == _o2.role.id) {
                    _o2.role.bHand = false;
                  }
                  remove_hand_to_sit(json.zw);
                  if (that.$chat.role.id == json.id) {
                    Bus.$emit("zhuchirenhuifu", json.flag);
                  }
                } else if (json.msg == "allmute") {
                  Bus.$emit("mute", json.data);
                } else if (json.msg == "roomOut") {
                  Bus.$emit("roomOut", json.data);
                } else if (json.msg == "cleanMsg") {
                  Bus.$emit("cleanMsg", json.data);
                } else if (json.msg == "setName") {
                  let player = _o2.playerMgr.getPlayerById(id);
                  if (player && player.namePanel) {
                    let height = player.namePanel.position.y;
                    // let color = player.namePanel.material.color;
                    player?.add_lable_to_char(json.data, height);
                  }

                  Bus.$emit("changeName", { id, data: json.data });
                }
              }
            }
          }
        }
      });
    } else {
      console.log("NO support WebSocket!");
    }

    function add_hand_to_sit(name) {
      let sit = get_sit(name);
      if (sit) {
        if (!handMtl) {
          handNode = new THREE.Object3D("handNode");
          _o2.scene.add(handNode);
          handMtl = new THREE.SpriteMaterial();
          let texLoader = new THREE.TextureLoader();
          handMtl.map = texLoader.load(require("../assets/newImg/举手.png"));
        }
        if (!dicSitHand[name]) {
          let sitHand = new THREE.Sprite();
          sitHand.position.set(sit.pos.x, sit.pos.y + 2000, sit.pos.z);
          sitHand.material = handMtl;
          sitHand.scale.set(500, 500, 1);
          handNode.add(sitHand);
          dicSitHand[name] = sitHand;
        }
      }
    }

    function remove_hand_to_sit(name) {
      if (dicSitHand && dicSitHand[name]) {
        handNode.remove(dicSitHand[name]);
        delete dicSitHand[name];
      }
    }

    function remove_all_hand() {
      if (dicSitHand) {
        for (const key in dicSitHand) {
          handNode.remove(dicSitHand[key]);
          delete dicSitHand[key];
        }
      }
    }

    var role_msg;
    function login(role, rid) {
      ws = new WebSocket("wss://o2vr.net:9103");
      player_name = role.name;
      if (player_name.substr(0, 6) == "admin_") {
        admin_mode = true;
        player_name = player_name.substr(6, 100);
      }
      room_id = rid;
      role_msg = JSON.stringify(role);
      WebSocketInit();
      // var tex = _o2.create_video_stream_tex(videoId);
      // _o2.add_video_stream_to_char(tex)
    }

    _o2.login = login;
    function move_player_position(player, time_passed) {
      // let pos = _o2.role.obj.position;
      // player.obj.position.set(pos.x,pos.y,pos.z);
      if (player.sit) return;
      let dst = player.target.clone();
      let clip_name = "idle";
      dst.y = player.obj.position.y;
      dst.sub(player.obj.position);
      let len = dst.length();
      if (len > 1) {
        clip_name = "walk";
      }
      if (len <= moveSpd * time_passed || len > moveSpd * 2) {
        //if (len > moveSpd * 2) {
        //角度
        let angle = player.targetAngle;
        if (!angle) angle = 0;
        let current = player.obj.rotation._y;
        if (!current) current = 0;
        let dst2 = (angle * 180) / 3.1415 - (current * 180) / 3.1415;
        if (Math.abs(dst2) > 180) {
          if (dst2 < 0) current = current - 3.1415 * 2;
          else current = current + 3.1415 * 2;
        }
        current = current * 0.9 + angle * 0.1;

        player.obj.rotation.set(0, current, 0);
        player.obj.position.set(player.target.x, player.target.y, player.target.z);
      } else {
        player.obj.lookAt(player.target.x, player.obj.position.y, player.target.z);
        let fullDst = player.target.clone().sub(player.obj.position);
        dst.y = 0;
        let temp = fullDst.length() / dst.length();
        fullDst.normalize();
        fullDst.multiplyScalar(moveSpd * temp * time_passed);
        // dst.normalize();
        // dst.multiplyScalar(3000 * time_passed);
        //dst.y = (player.target.y - player.obj.position.y) * (time_passed/0.5);
        player.obj.position.add(fullDst);
        //
      }
      //player.set_heigth(colli,ground_height,time_passed);

      //动作
      if (player.last_clip_name != clip_name) {
        if (!player.cold_down) player.cold_down = 1;
        player.cold_down = -1;
        if (player.cold_down <= 0) {
          // console.log(player.cold_down, clip_name);
          //player.playClip(clip_name, true);
          player.setState(clip_name);
          //console.log(clip_name);
          //_o2.play_skeleton_clip(player.obj, clip_name, true);
          player.last_clip_name = clip_name;
          player.cold_down = 1000;
        }
      }
    }

    function send_scene_state() {
      let msgstr = JSON.stringify(scene_state);
      msgstr = "1058," + msgstr;
      that.$chat.ws.send(msgstr);
    }

    function screen_url(url) {
      // let bPush = false;
      // let arrStr = url.split('?');
      // if(arrStr && arrStr.length > 0){
      //   let type = arrStr[0].substr(arrStr[0].length - 3,arrStr[0].length - 1);
      //   bPush = (type == 'flv');
      // }
      // if(bPush){
      //   axios.post("https://api.wh12345.net/live/liveSig2.php",
      //   {url:''}).then(res=>{

      //   });
      // }
      // else{
      //   scene_state.screen_url = url;
      //   console.log("large_screen_url", url);
      //   send_scene_state();
      // }
      //scene_state.screen_url = url;
      scene_state.screen_url = url;
      console.log("large_screen_url", url);
      send_scene_state();
    }
    _o2.screen_url = screen_url;
    var tishicount = 0;
    function frame_move(time_passed) {
      //镜头距离
      let dis = _o2.cameraControls.target.distanceTo(_o2.camera.position);
      if (_o2.role && _o2.role.obj && _o2.role.obj.visible != dis > 1000) {
        _o2.role.obj.visible = !_o2.role.obj.visible;
      }

      //进入传送阵
      if (_o2.csm && _o2.role) {
        // csm_cold = 0;
        let pos = _o2.role.obj.position.clone();
        pos.sub(_o2.csm.position);
        //pos.y = 0;
        let len = _o2.dreamType == 0 ? 2000 : 1000;
        if (pos.length() <= len) {
          csm_cold += time_passed;
          if (csm_cold >= tishicount && tishicount <= 3) {
            tishicount++;
            that.$message.success(
              `再过${parseInt(3 - csm_cold) + 1}秒即将传送至${
                _o2.dreamType == 0 ? "进化空间内部" : "梦界空间外景"
              }`
            );
          }

          if (csm_cold >= 3) {
            tishicount = 0;
            _o2.dreamType == 0
              ? localStorage.setItem("dreamType", 1)
              : localStorage.setItem("dreamType", 0);
            location.reload();
            csm_cold = 0;
          }
        } else {
          csm_cold = 0;
        }
      }
      //音量调节
      if (
        show_screen_played &&
        show_video_object &&
        show_video_object.material &&
        show_video_object.material.map &&
        show_video_object.material.map.image
      ) {
        let vec1 = posPlayer.clone();
        vec1.y = 0;
        vec1.z += 37820;
        let len = vec1.length();
        let volume = (5000 - (len - 10000)) / 5000;
        if (volume < 0) volume = 0;
        if (volume > 1) volume = 1;
        show_video_object.material.map.image.volume = volume;
        //console.log("音量调节距离",len);
      }

      // 处理pops
      {
        let now1 = Date.now();
        let new_list = [];
        for (let i = 0; i < chat_pops.length; i++) {
          let chat = chat_pops[i];
          let dst = now1 - chat.beginTime;
          if (dst > 10000) {
            chat.parent.remove(chat);
          } else {
            new_list.push(chat);
          }
        }
        chat_pops = new_list;
      }

      {
        let now1 = Date.now();
        let new_list = [];
        for (let i = 0; i < gif_pops.length; i++) {
          let chat = gif_pops[i];
          let dst = now1 - chat.beginTime;
          if (dst > 5000) {
            chat.parent.remove(chat);
          } else {
            new_list.push(chat);
          }
        }
        gif_pops = new_list;
      }

      //3秒进入房间的cold_down
      if (region_cold_down) {
        let now1 = Date.now();
        if (now1 - region_cold_down > 3000) {
          //传送
          region_cold_down = null;
          {
            let name = current_region.name;
            if (name == "Box001") {
              console.log("进入展区");
              return;
            }
          }

          let rtc_id = rtc_room_list[current_region.name];
          console.log(rtc_id);
          if (rtc_id > 0) {
            let uuid = region_screen[current_region.name];
            if (uuid) {
              current_room_screen = _o2.search_obj_by_guid2(uuid);
              _o2.current_room_screen = current_room_screen;
              current_screen_mat = current_room_screen.material;
              current_room_screen.material = new THREE.MeshBasicMaterial();
              current_room_screen.material.map = current_screen_mat.map;
              console.log(current_room_screen);
            }
            current_rtc = rtc_id;
            Bus.$emit("clientLogin", rtc_id);
          }
        }
      }
      if (_o2.playerMgr.arrPlayer) {
        //更新多人在线其他角色位置
        for (let i = 0; i < _o2.playerMgr.arrPlayer.length; i++) {
          let player = _o2.playerMgr.arrPlayer[i];
          if (player.obj && player.obj.visible && player != _o2.role) {
            move_player_position(player, time_passed);
          }
        }
      }
      //入座了
      if (bTempSit) {
        //更新角色和相机位置到posPlayer
        posPlayer.set(pos_sit.x, pos_sit.y, pos_sit.z);
        let oldtarget = _o2.cameraControls.target.clone();
        let dir = _o2.camera.position.clone();
        dir.sub(oldtarget);
        let neweye = posPlayer.clone();
        neweye.add(dir);
        if (!_o2.fixed_view) {
          _o2.camera.position.set(neweye.x, neweye.y + viewHeight, neweye.z);
          _o2.cameraControls.target.set(
            posPlayer.x,
            posPlayer.y + viewHeight,
            posPlayer.z
          );
        }
        clip_name = "sit";
        //朝向
        if (_o2.role && _o2.role.obj) {
          _o2.role.obj.position.set(posPlayer.x, posPlayer.y, posPlayer.z);
          _o2.role.obj.rotation.set(0, angle_sit, 0);
          if (last_clip_name != clip_name) {
            _o2.role.playClip(clip_name, true);
            last_clip_name = clip_name;
          }
        }
        return;
      }

      //处理角色移动
      let bCtrlMove = false;
      let movedir = moveDir.clone();
      let target = posPlayer.clone();
      if (_o2.role && _o2.role.obj) {
        if (movedir.length() <= 0.001) {
          movedir.set(0, 0, -1);
        }
        let dir = new THREE.Vector3();
        dir.set(0, 0, 0);
        dir.applyMatrix4(_o2.camera.matrixWorld);
        movedir.applyMatrix4(_o2.camera.matrixWorld);
        movedir.sub(dir);
        movedir.y = 0;
        movedir.normalize();
      }
      let clip_name = "idle";
      if (!bCtrlStop) {
        if (bAutoMove) {
          bCtrlMove = true;
          clip_name = "walk";
          //先自动移动
          let dst = targetAutoMove.clone();
          dst.sub(posPlayer);
          dst.y = 0;
          let len = dst.length();
          if (len <= moveSpd * time_passed) {
            bAutoMove = false;
            bMoveFinish = true;
            target = targetAutoMove.clone();
          } else {
            dst.normalize();
            movedir.set(dst.x, dst.y, dst.z);
            dst.multiplyScalar(moveSpd * time_passed);
            target = posPlayer.clone();
            target.add(dst);
          }
        } else if (bKeyCtrl) {
          //然后键盘控制
          let move = moveDir.clone();
          move.normalize();
          let len = move.length();
          if (len > 0.01) {
            bCtrlMove = true;
            clip_name = "walk";
            let dir = new THREE.Vector3();
            dir.set(0, 0, 0);
            dir.applyMatrix4(_o2.camera.matrixWorld);
            move.applyMatrix4(_o2.camera.matrixWorld);
            move.sub(dir);
            move.y = 0;
            move.normalize();
            move.multiplyScalar(time_passed * moveSpd);
            target = posPlayer.clone();
            target.add(move);
          }
        }
        let oldpos_player = posPlayer.clone();
        move_with_colli(target, time_passed);
        if (bAutoMove) {
          //判断下移动距离停止移动
          oldpos_player.sub(posPlayer);
          let len = oldpos_player.length();
          if (len < 1) {
            bAutoMove = false;
            bMoveFinish = true;
          }
        }
        if (_o2.role && _o2.role.obj) {
          //更新角色和相机位置到posPlayer
          let oldtarget = _o2.cameraControls.target.clone();
          let dir = _o2.camera.position.clone();
          dir.sub(oldtarget);
          let neweye = posPlayer.clone();
          neweye.add(dir);
          _o2.role.obj.position.set(posPlayer.x, posPlayer.y, posPlayer.z);
          if (!_o2.fixed_view) {
            _o2.camera.position.set(neweye.x, neweye.y + viewHeight, neweye.z);
            _o2.cameraControls.target.set(
              posPlayer.x,
              posPlayer.y + viewHeight,
              posPlayer.z
            );
          }
        }

        ws_pos_cold_down = ws_pos_cold_down - time_passed;
        if (ws_pos_cold_down < 0) {
          ws_pos_cold_down = 0.5;
          if (admin_mode) send_camera_to_server();
          send_position_to_server();
        }

        //朝向
        if (_o2.role && _o2.role.obj) {
          let angle = Math.asin(movedir.x);
          if (movedir.z < 0) angle = 3.1415 - angle;
          target_angle = angle;
          let current = _o2.role.obj.rotation._y;
          let dst = (target_angle * 180) / 3.1415 - (current * 180) / 3.1415;
          if (Math.abs(dst) > 180) {
            if (dst < 0) current = current - 3.1415 * 2;
            else current = current + 3.1415 * 2;
          }
          current = current * 0.9 + target_angle * 0.1;
          if (!bCtrlMove) current = old_angle;
          else old_angle = current;
          _o2.role.obj.rotation.set(0, current, 0);
          if (last_clip_name != clip_name) {
            //_o2.role.playClip(clip_name, true);
            _o2.role.setState(clip_name);
            //_o2.play_skeleton_clip(_o2.role.obj, clip_name, true);
            last_clip_name = clip_name;
          }
        }
      }
      room_test();

      if (bMoveFinish) {
        //移动完毕，测试是否到座位
        bMoveFinish = false;
        let min_dst = 1000 * 1000;
        let found_sit;
        for (let i = 0; i < all_sits.length; i++) {
          let sit = all_sits[i];
          let sitpos = sit.pos;
          let dsty = Math.abs(sitpos.y - posPlayer.y);
          let dstxz =
            (sitpos.x - posPlayer.x) * (sitpos.x - posPlayer.x) +
            (sitpos.z - posPlayer.z) * (sitpos.z - posPlayer.z);
          // console.log(sit.name,dsty,dstxz);
          if (dsty < 1000 && dstxz < min_dst) {
            min_dst = dstxz;
            found_sit = sit;
          }
        }

        if (found_sit) {
          try_sit(found_sit);
        }
      }
    }

    function switch_view(idx) {
      if (idx > 0) {
        _o2.fixed_view = true;
      } else {
        _o2.cameraControls.maxDistance = 5000;
        _o2.fixed_view = false;
      }
      if (idx == 2) {
        //
        _o2.switch_camera(-680, 8085, 11154, -680, 6785, 4746);
        _o2.cameraControls.maxDistance = 10000;
      }
      if (idx == 1) {
        //
        _o2.switch_camera(-133, 722, 5829, -20, 2553, 1035);
        _o2.cameraControls.maxDistance = 10000;
      }
    }
    _o2.switch_view = switch_view;
    function room_test() {
      //进出房间测试
      let testpos = posPlayer.clone();
      testpos.y = testpos.y + 1000;
      if (!current_region) {
        //没有房间需要进入房间
        for (let i = 0; i < regions.length; i++) {
          let region = regions[i];
          if (!region.matrixWorldInverse) {
            region.matrixWorldInverse = region.matrixWorld.clone();
            region.matrixWorldInverse.invert();
          }

          let t1 = testpos.clone();
          t1.applyMatrix4(region.matrixWorldInverse);
          if (
            t1.x > 0 &&
            t1.x < 1000 &&
            t1.y > 0 &&
            t1.y < 1000 &&
            t1.z > 0 &&
            t1.z < 1000
          ) {
            current_region = region;
            console.log("进入区域" + region.name);
            {
              let obj = _o2.search_obj_by_name("展板_02");
              //播放视频
              if (obj) {
                if (obj.sw) {
                  let tex = _o2.get_video_tex(obj.sw.publish_url, true);
                  if (tex) {
                    obj.oldlightmap = obj.material.lightMap;
                    obj.material.lightMap = _o2.map_white;
                    obj.oldmap = obj.material.map;
                    obj.material.map = tex;
                    show_screen_played = true;
                    show_video_object = obj;
                  }
                }
              }
            }
            //3秒后再进入该房间
            region_cold_down = Date.now();
            break;
          }
        }
      } else {
        let region = current_region;
        if (!region.matrixWorldInverse) {
          region.matrixWorldInverse = region.matrixWorld.clone();
          region.matrixWorldInverse.invert();
        }
        let t1 = testpos.clone();
        t1.applyMatrix4(region.matrixWorldInverse);
        if (
          t1.x < -100 ||
          t1.x > 1100 ||
          t1.y < -100 ||
          t1.y > 1100 ||
          t1.z < -100 ||
          t1.z > 1100
        ) {
          console.log("离开区域,", current_region.name);
          if (show_screen_played) {
            show_screen_played = false;
            let obj = _o2.search_obj_by_name("展板_02");
            //播放视频
            if (obj) {
              if (obj.oldmap) {
                obj.material.map = obj.oldmap;
                obj.material.lightMap = obj.oldlightmap;
              }
              _o2.stop_video_tex();
            }
          }
          region_cold_down = null;
          current_region = null;
        }
      }
    }

    function onSwitchCameraFinish(x, y, z, xx, yy, zz) {
      if (_o2.role && _o2.role.obj) {
        bCtrlStop = false;
        bKeyCtrl = true;
        bAutoMove = false;
        //_o2.role.obj.position.set(x, 0, z);
        posPlayer.x = _o2.role.obj.position.x;
        posPlayer.y = _o2.role.obj.position.y;
        posPlayer.z = _o2.role.obj.position.z;
        let dir = new THREE.Vector3();
        dir.x = x - xx;
        dir.y = 0;
        dir.z = z - zz;
        dir = moveDir.normalize();
        let angle = Math.atan2(dir.z, dir.x);
        angle = (angle / 3.1415) * 180;
        send_transfer_position_to_server(angle);
      }
    }
    function send_transfer_position_to_server(angle) {
      // if (!ws || !bLoginSuccess) return;
      // let str =
      //   "transfer," +
      //   Math.ceil(posPlayer.x) +
      //   "," +
      //   Math.ceil(posPlayer.z) +
      //   "," +
      //   Math.ceil(angle);
      // if (str != last_pos_send) {
      //   console.log(str);
      //   last_pos_send = str;
      //   that.$chat.ws.send(str);
      // }
    }
    // function initSitLable() {
    //   axios.get("https://o2vr.net/bmw/php/sits.php").then((res) => {
    //     if (res.data) {
    //       res.data.forEach((e) => {
    //         let x = parseFloat(e.x);
    //         let y = parseFloat(e.y);
    //         let z = parseFloat(e.z);
    //         add_lable_to_sit(e.name, x, y, z);
    //       });
    //     }
    //   });
    // }
    //initSitLable();
    function add_lable_to_sit(str, x, y, z) {
      let canvas = document.createElement("canvas");
      canvas.width = 80;
      canvas.height = 32;
      const drawingContext = canvas.getContext("2d");
      drawingContext.fillStyle = "#000000";
      drawingContext.globalAlpha = 0.1;
      drawingContext.fillRect(0, 0, 80, 32);
      drawingContext.globalAlpha = 1;
      drawingContext.fillStyle = "#ffffff";
      drawingContext.font = "20px Georgia";
      drawingContext.textAlign = "center";
      drawingContext.fillText(str, 40, 14);
      let map = new THREE.CanvasTexture(canvas);
      let sitSprite = new THREE.Sprite(
        new THREE.SpriteMaterial({ map: map, color: "#ffffff" })
      );
      sitSprite.position.set(x, y + 800, z);
      sitSprite.scale.set(600, 120, 1);
      sitSprite.material.depthWrite = false;
      _o2.scene.add(sitSprite);
    }
    var that = this;
    Bus.$on("keyFlag", (e) => {
      console.log(e);
      that.keyFlag = e;
    });

    function get_player(id) {
      let player;
      if (_o2.role.id == id) {
        player = _o2.role;
      } else if (_o2.players) {
        for (let i = 0; i < _o2.players.length; i++) {
          if (_o2.players[i].id == id) {
            player = _o2.players[i];
            break;
          }
        }
      }

      return player;
    }
    function onKeyDown(e) {
      if (that.keyFlag) {
        return;
      }
      // if (e.key == "B" || e.key == "b") {
      //   bBoardEditMode = !bBoardEditMode;
      //   if (bBoardEditMode) bTempSit = false;
      //   alert("展板编辑模式:" + bBoardEditMode);
      //   return;
      // }
      // if (e.key == "n" || e.key == "N") {
      //   if (user_power >= 5) {
      //     bShowAdminMode = !bShowAdminMode;
      //     if (bShowAdminMode) bBoardEditMode = false;
      //     alert("素材审核模式:" + bShowAdminMode);
      //   }
      //   return;
      // }
      if (bTempSit) {
        return;
        switch (e.key) {
          case "ArrowLeft":
            angle_sit = angle_sit + (5 / 180) * 3.1416;
            break;
          case "ArrowRight":
            angle_sit = angle_sit - (5 / 180) * 3.1416;
            break;
          case "ArrowUp":
            pos_sit.y = pos_sit.y + 5;
            break;
          case "ArrowDown":
            pos_sit.y = pos_sit.y - 5;
            break;
          case "r":
          case "R":
            let str = prompt("输入座位名称(楼层-房间-座位号)");
            if (str && str != "") {
              let url =
                that.url +
                "/add_res.php?name=" +
                str +
                "&x=" +
                Math.ceil(pos_sit.x) +
                "&y=" +
                Math.ceil(pos_sit.y) +
                "&z=" +
                Math.ceil(pos_sit.z) +
                "&angle=" +
                Math.round((180 * angle_sit) / 3.1416);
              let xhr = new XMLHttpRequest();
              xhr.open("get", url, true);
              xhr.responseType = "text";
              xhr.send();
              add_lable_to_sit(str, pos_sit.x, pos_sit.y, pos_sit.z);
            }
            break;
          case "q":
          case "Q":
            // if (name_sit) {
            //   let msg = "up," + name_sit;
            //   that.$chat.ws.send(msg);
            //   name_sit = null;
            // }

            // if (current_region > 0) {
            //   current_region = null;
            //   current_room_screen=null;
            //   _o2.current_room_screen=null;
            //   Bus.$emit("clientOut", true);
            // }
            // bTempSit = false;
            // old_angle = angle_sit;
            break;
          case "w":
          case "W":
            angle_sit = 0;
            break;
          case "S":
          case "s":
            angle_sit = 3.1416;
            break;
          case "a":
          case "A":
            angle_sit = 3.1416 / 2;
            break;
          case "d":
          case "D":
            angle_sit = -3.1416 / 2;
            break;
        }
        return;
      }
      if (bCtrlStop) return;
      switch (e.key) {
        case "p":
        case "P":
          {
            // _o2.chat_pop(
            //   _o2.player,
            //   "这是一大长串文字，不知道能不能自动换行啥的道能不能自动换行啥的道能不能自动换行啥的"
            // );
          }
          break;
        case "q":
        case "Q":
          moveDir.y = moveSpd;
          bAutoMove = false;
          break;
        case "e":
        case "E":
          moveDir.y = -moveSpd;
          bAutoMove = false;
          break;
        case "w":
        case "W":
          moveDir.z = -moveSpd;
          bAutoMove = false;
          break;
        case "a":
        case "A":
          moveDir.x = -moveSpd;
          bAutoMove = false;
          break;
        case "s":
        case "S":
          moveDir.z = moveSpd;
          bAutoMove = false;
          break;
        case "d":
        case "D":
          moveDir.x = moveSpd;
          bAutoMove = false;
          break;
      }
      // if (document.o2) {
      //   document.o2.translate_camera(moveDir.x, moveDir.y, moveDir.z);
      // }
      //console.log(document.o2.player.position);
    }

    Bus.$on("key", (target) => {
      switch (target) {
        case "q":
        case "Q":
          moveDir.y = moveSpd;
          bAutoMove = false;
          break;
        case "e":
        case "E":
          moveDir.y = -moveSpd;
          bAutoMove = false;
          break;
        case "w":
        case "W":
          moveDir.z = -moveSpd;
          bAutoMove = false;
          break;
        case "a":
        case "A":
          moveDir.x = -moveSpd;
          bAutoMove = false;
          break;
        case "s":
        case "S":
          moveDir.z = moveSpd;
          bAutoMove = false;
          break;
        case "d":
        case "D":
          moveDir.x = moveSpd;
          bAutoMove = false;
          break;
        case "all":
          moveDir.y = 0;
          moveDir.x = 0;
          moveDir.z = 0;
          break;
      }
      // if (document.o2) {
      //   document.o2.translate_camera(moveDir.x, moveDir.y, moveDir.z);
      // }
    });
    function onKeyUp(e) {
      if (bCtrlStop) return;
      switch (e.key) {
        case "q":
        case "Q":
          moveDir.y = 0;
          break;
        case "e":
        case "E":
          moveDir.y = 0;
          break;
        case "w":
        case "W":
          moveDir.z = 0;
          break;
        case "a":
        case "A":
          moveDir.x = 0;
          break;
        case "s":
        case "S":
          moveDir.z = 0;
          break;
        case "d":
        case "D":
          moveDir.x = 0;
          break;
      }
      // if (document.o2) {
      //   document.o2.translate_camera(moveDir.x, moveDir.y, moveDir.z);
      // }
    }

    function play_animation(idx) {
      let ani = _o2.animation.timelines[idx];
      if (ani) {
        ani.current_time = 0;
        ani.play();
      }
    }
    _o2.play_animation = play_animation;
    function try_sit(sit) {
      //let msg = "down," + sit.name;
      //that.$chat.ws.send(msg);
      let role = that.$chat.role;
      // let requestSitUrl =
      //   "https://api.wh12345.net:9300/down/?pid=" +
      //   role.id +
      //   "&pname=" +
      //   role.name +
      //   "&sitname=" +
      //   sit.name;
      // axios.get(requestSitUrl);
      for (const key in vip_sit_info) {
        if (vip_sit_info[key].id == sit.name) {
          let obj = vip_sit_info[key].obj;
          if (!obj || !obj.visible || _o2.role.vip < 3) {
            return;
          }
        }
      }
      let str = netCmd.CMD_USER_SIT_DOWN + "," + sit.name + "," + _o2.role.id;
      that.$chat.ws.send(str);
    }

    let container = document.getElementById("container");
    container.addEventListener("pointerdown", onMouseDown, false);
    container.addEventListener("pointerup", onMouseUp, false);
    container.addEventListener("touchdown", onMouseDown, false);
    container.addEventListener("touchup", onMouseUp, false);
    document.addEventListener("keydown", onKeyDown, false);
    document.addEventListener("keyup", onKeyUp, false);
    document.o2.frame_move_recall.push(frame_move);
    document.o2.switch_camera_finish.push(onSwitchCameraFinish);
  },
  created() {
    this.$o2Function.createDrag();
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.shadow {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}
</style>
